import axios from "@/helpers/axiosAPI";

// initial state
const state = () => ({
  loading: { getUserNotifications: false, putNotificationReadArchived: false },
  dropdownNotifications: [],
  userNotifications: [],
  uploadNotification: {},
  unreadUnarchivedNotificationsCount: null,
  readSwitchValue: true,
  archiveSwitchValue: false,
  commentsSwitchValue: false,
  issueStatusesSwitchValue: false,
  alertsSwitchValue: false,
  isNotificationNew: false,
  paginationOptions: {},
  notificationPagination: {},
  dropdownLoading: false,
});

// actions
const actions = {
  async getUserNotifications({ commit, dispatch }, payload) {
    try {
      if (payload.container) {
        commit("updateDropdownLoading", true);
      } else {
        commit("setLoading", { name: "getUserNotifications", value: true });
      }
      let params = {};

      if (payload && Object.keys(payload).length > 0) {
        if ("limit" in payload || "offset" in payload) {
          params.limit = payload.limit;
          params.offset = payload.offset;
        }
        if ("read" in payload && "archived" in payload) {
          params.read = payload.read;
          params.archived = payload.archived;
        }
        if ("comments" in payload) {
          params.comments = payload.comments;
        }
        if ("issue_statuses" in payload) {
          params.issue_statuses = payload.issue_statuses;
        }
        if ("alerts" in payload) {
          params.alerts = payload.alerts;
        }
        if ("sortDesc" in payload) {
          params.sort_desc = payload.sortDesc;
        }
        if ("get_total_count" in payload) {
          params.get_total_count = payload.get_total_count;
        }
        if ("get_unread_unarchived_count" in payload) {
          params.get_unread_unarchived_count =
            payload.get_unread_unarchived_count;
        }
      }

      const res = await axios.get("/notifications", { params });

      if (res.status === 200) {
        for (const item of res.data.notifications) {
          if (item.read_ts !== "NaT" && item.read_ts !== null) {
            item.read = true;
          } else {
            item.read = false;
          }
          if (item.archived_ts !== "NaT" && item.archived_ts !== null) {
            item.archived = true;
          } else {
            item.archived = false;
          }
          const splitTime = item.created_ts.split("T");
          item.created_ts = splitTime[0] + " " + splitTime[1];
        }
        if (res.data.n_unread_unarchived >= 0) {
          commit(
            "setUnreadUnarchivedNotificationsCount",
            res.data.n_unread_unarchived,
          );
        }
        if (payload.container && res.data.notifications.length <= 20) {
          commit("setDropdownNotifications", res.data.notifications);
        } else {
          commit("setNotifications", res.data.notifications);
          const paginationObj = {
            start_index: res.data.start_index,
            end_index: res.data.end_index,
            total: res.data.total,
          };
          commit("setPaginationObject", paginationObj);
        }
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getUserNotifications",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getUserNotifications", error },
        { root: true },
      );
    }

    if (payload.container) {
      commit("updateDropdownLoading", false);
    } else {
      commit("setLoading", { name: "getUserNotifications", value: false });
    }
  },
  async putNotificationReadArchived({ commit, dispatch, rootState }, payload) {
    try {
      if (payload.container === "dropdown") {
        commit("updateDropdownLoading", true);
      }
      if (!payload.container) {
        commit("setLoading", {
          name: "putNotificationReadArchived",
          value: true,
        });
      }

      let id = null;
      let body = {};
      let res = null;

      if (Array.isArray(payload)) {
        res = await axios.put("/notifications", payload);
      } else {
        if (payload?.id) {
          id = payload.id;
          body.read = payload.read;
          body.archived = payload.archived;
        }

        res = await axios.put(`/notifications/${id}`, body);
      }

      if (res.status === 200) {
        commit("updateNotifications", payload);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "putNotificationReadArchived",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "putNotificationReadArchived", error },
        { root: true },
      );
    }
    if (payload.container) {
      commit("updateDropdownLoading", false);
    }
    if (!payload.container) {
      commit("setLoading", {
        name: "putNotificationReadArchived",
        value: false,
      });
    }
  },
  saveUploadNotification({ commit }, uploadNotification) {
    commit("setUploadNotification", uploadNotification);
  },
  updateReadSwitch({ commit }, switchValue) {
    commit("updateReadSwitchValue", switchValue);
  },
  updateArchiveSwitch({ commit }, switchValue) {
    commit("updateArchiveSwitchValue", switchValue);
  },
  updateCommentsSwitch({ commit }, switchValue) {
    commit("updateCommentsSwitchValue", switchValue);
  },
  updateIssueStatusesSwitch({ commit }, switchValue) {
    commit("updateIssueStatusesSwitchValue", switchValue);
  },
  updatealertsSwitch({ commit }, switchValue) {
    commit("updatealertsSwitchValue", switchValue);
  },
  updateIsNotificationNew({ commit }, value) {
    commit("setIsNotificationNew", value);
  },
  clearReadNotifications({ commit }) {
    commit("setReadNotifications", {});
  },
  saveNotificationPagination({ commit }, payload) {
    commit("setNotificationPagination", payload);
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setDropdownNotifications(state, data) {
    state.dropdownNotifications = data;
  },
  setNotifications(state, data) {
    state.userNotifications = data;
  },
  setUnreadUnarchivedNotificationsCount(state, data) {
    state.unreadUnarchivedNotificationsCount = data;
  },
  updateNotifications(state, data) {
    for (const notification of state.dropdownNotifications) {
      if (notification.id === data.id) {
        notification.read = data.read;
        notification.archived = data.archived;
        break;
      }
    }
    for (const notification of state.userNotifications) {
      if (notification.id === data.id) {
        notification.read = data.read;
        notification.archived = data.archived;
        break;
      }
    }
  },
  setUploadNotification(state, data) {
    state.uploadNotification = data;
  },
  setIsNotificationNew(state, data) {
    state.isNotificationNew = data;
  },
  updateReadSwitchValue(state, data) {
    state.readSwitchValue = data;
  },
  updateArchiveSwitchValue(state, data) {
    state.archiveSwitchValue = data;
  },
  updateCommentsSwitchValue(state, data) {
    state.commentsSwitchValue = data;
  },
  updateIssueStatusesSwitchValue(state, data) {
    state.issueStatusesSwitchValue = data;
  },
  updatealertsSwitchValue(state, data) {
    state.alertsSwitchValue = data;
  },
  setPaginationObject(state, data) {
    state.paginationOptions = data;
  },
  setNotificationPagination(state, data) {
    state.notificationPagination = data;
  },
  updateDropdownLoading(state, data) {
    state.dropdownLoading = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
