import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import { COLORS } from "@/helpers/colors";

Vue.use(Vuetify);
const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#115b8a",
        primaryBlue: "#115b8a",
        fileOption: "#3c97b0",
        green: COLORS.green,
        error: COLORS.red,
        background: "#f7f7f8",
        secondary: "#fff",
        inverse: "#000",
        widgetText: "#212b36",
        widgetH3: "#212b36",
        backdrop: "#bfbfbf",
        black1: "#000",
        black2: "#212b36",
        black3: "#242426",
        black4: "#6e6e73",
        black5: "#56626e",
        black6: "#bbbbbf",
        black7: "#4d4d4f",
        black8: "#86868d",
        black9: "#a0a0a6",
        black10: "#efeff0",
        black11: "#d5d5d8",
        black12: "#b7c0c6",
        white: "#ffffff",
        white1: "#fff",
        white2: "#f7f7f8",
        hover: "#eeeeee",
        text: "#242426",
        docTypeText: "#242426",
        border: "#bbbbbf",
        gray: "#a6a6a6",
        editEvidenceBackground: "#ffffff",
        searchBackground: "#f8f8f8",
        pcBorderColor: "#e5e5e6",
        pcSpinnerOverlay: "#131920",
        stalenessYellow: COLORS.yellow,
        stalenessGreen: COLORS.green,
        availableSignalsHover: "#1a1a1a",
        addSignalIcon: "#007c67",
        disabledText: "#242426",
        mediumGray: "#e6e6e6",
        blueBackground: "#3c97b0",
        hoverPrimary: "#7abfd1",
        issueModal: "#f7f7f8",
        converterBorders: "#bfbfbf",
        newIssues: COLORS.yellow,
        inProgressIssues: COLORS.blue,
        closedIssues: COLORS.green,
        issueStatusBadgeFont: "#242426",
        textInputBackground: "#fff",
        contrastBackground: "#ffffff",
        search: "#fbfbfb",
        componentImageBorder: "#7c7c83",
      },
      dark: {
        primary: "#3c97b0",
        primaryBlue: "#115b8a",
        fileOption: "#3c97b0",
        green: COLORS.green,
        error: COLORS.red,
        background: "#121212",
        secondary: "#1e1e1e",
        border: "#616161",
        inverse: "#fff",
        backdrop: "#262626",
        white: "#ffffff",
        white1: "#fff",
        widgetHeader: "#fff",
        widgetText: "#c9d1d9",
        widgetFooter: "#fff",
        widgetH3: "#fff",
        hover: "#616161",
        docTypeText: "#242426",
        black3: "#b0b0b5",
        black10: "#1e1e1e",
        text: "#c9d1d9",
        gray: "#8c8c8c",
        editEvidenceBackground: "#1e1e1e",
        searchBackground: "#333333",
        pcBorderColor: "#d8dbe0",
        pcSubtleBorderColor: "#333333",
        stalenessYellow: COLORS.yellow,
        stalenessGreen: COLORS.green,
        availableSignalsHover: "#ffffff",
        addSignalIcon: "#007c67",
        disabledText: "#c9d1d9",
        mediumGray: "#4d4d4d",
        blueBackground: "#276272",
        updatedText: "#9e9e9e",
        hoverPrimary: "#7abfd1",
        issueModal: "#262626",
        converterBorders: "#4d4d4d",
        newIssues: COLORS.yellow,
        inProgressIssues: COLORS.blue,
        closedIssues: COLORS.green,
        issueStatusBadgeFont: "#242426",
        textInputBackground: "#121212",
        contrastBackground: "#262626",
        search: "#1e1e1e",
        componentImageBorder: "#808080",
      },
    },
  },
});

export default vuetify;
