import axios from "@/helpers/axiosAPI";

// initial state
const state = () => ({
  loading: {
    getTurbine: false,
    getPaginatedIssuesForIssuesTable: false,
    getChecks: false,
  },
  turbineData: {},
  paginatedIssuesForIssuesTable: [],
  paginatedIssuesTotal: 0,
  checks: {},
  issuesOpen: [],
  issuesFixed: [],
  issuesResolved: [],
  issuesRequireAttention: [],
  confirmedIssues: [],
  converterData: {},
  mainBearingData: {},
  subsystemComponents: [],
  abortControllerPaginatedIssues: new AbortController(),
});

const actions = {
  // Top dashboard cards
  async getTurbine({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getTurbine", value: true });
      commit("setTurbineData", {});
      const params = {
        get_issues_summary: payload.getIssuesSummary,
        get_subscription: payload.getSubscription,
        recursive: payload.recursive,
      };

      const res = await axios.get(`/turbines/${payload.turbineId}`, {
        params,
      });

      if (res.status === 200) {
        commit("setTurbineData", res.data);
      } else {
        commit("setTurbineData", {});
        dispatch(
          "error/raiseError",
          {
            name: "getEntity",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      commit("setTurbineData", {});
      dispatch(
        "error/raiseError",
        { name: "getEntity", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getTurbine", value: false });
  },
  async getPaginatedIssuesForIssuesTable(
    { commit, dispatch, rootState },
    payload,
  ) {
    try {
      // Cancel the previous request
      rootState.turbine.abortControllerPaginatedIssues.abort();
      // Create a new abort controller for the current request
      commit("setAbortControllerPaginatedIssues");
      commit("setLoading", {
        name: "getPaginatedIssuesForIssuesTable",
        value: true,
      });
      commit("setPaginatedIssues", []);
      commit("setPaginatedIssuesTotal", 0);

      let includeStatusString = "";
      const itemsPerPage =
        typeof payload.items_per_page === "string"
          ? parseInt(payload.items_per_page)
          : payload.items_per_page;
      const page =
        typeof payload.page === "string"
          ? parseInt(payload.page)
          : payload.page;
      const sortDesc =
        typeof payload.sort_desc === "string"
          ? parseInt(payload.sort_desc)
          : payload.sort_desc;
      let params = {
        summarize: 0,
      };
      params.limit = itemsPerPage;
      params.offset = (page - 1) * itemsPerPage;
      params.sort_desc = sortDesc;
      params.group_by = null;
      if (payload.sort_by) {
        params.sort_by = payload.sort_by;
      }
      if ("include_statuses" in payload) {
        let statuses = [];
        if (payload.include_statuses.length > 0) {
          for (const item of payload.include_statuses) {
            if (item.includes("_")) {
              const splitItem = item.split("_");
              const status = `${splitItem[0]} ${splitItem[1]}`;
              statuses.push(status);
            } else {
              statuses.push(item);
            }
          }
          statuses.forEach((status) => {
            includeStatusString += `&include_status=${status}`;
          });
        } else {
          // Empty all vars and release loading if no statuses supplied
          commit("setPaginatedIssues", []);
          commit("setPaginatedIssuesTotal", 0);
          commit("setLoading", {
            name: "getPaginatedIssuesForIssuesTable",
            value: false,
          });
          return;
        }
      }
      if (payload.search_for) {
        params.search_for = payload.search_for;
      }
      if (payload.component_type) {
        params = {};
        if (payload.component_type === "rotor_shaft_assembly") {
          params.component_type = "main_bearing";
        } else {
          params.component_type = payload.component_type;
        }
        params.get_losses_gains = true;
        params.summarize = 0;
        params.exclude_unconfirmed = false;
      }
      if (payload.new_page === "turbine") {
        params = {};
        params.get_losses_gains = true;
        params.summarize = 0;
        params.exclude_unconfirmed = false;
      }

      const res = await axios.get(
        `/turbines/${payload.turbineId}/issues?${includeStatusString}`,
        {
          signal: rootState.site.abortControllerPaginatedIssues.signal,
          params,
        },
      );

      if (res.status === 200) {
        let data = res.data.results.map((item) => ({
          ...item,
          loss_upper_usd: item.aep_loss_pct < 0.05 ? 0 : item.loss_upper_usd,
        }));
        commit("setPaginatedIssues", data);
        commit("setPaginatedIssuesTotal", res.data.total);

        if (params.summarize !== 1) {
          var issuesOpen = res.data.results.filter((issue) => issue.is_open);
          var issuesFixed = res.data.results.filter((issue) => issue.is_fixed);
          var issuesResolved = res.data.results.filter(
            (issue) => issue.is_resolved,
          );
          var issuesRequireAttention = res.data.results.filter(
            (issue) => issue.requires_external_input,
          );
        }
        commit("setIssuesOpen", issuesOpen);
        commit("setIssuesFixed", issuesFixed);
        commit("setIssuesResolved", issuesResolved);
        commit("setIssuesRequireAttention", issuesRequireAttention);
        commit("setConfirmedIssues", data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getPaginatedIssuesForIssuesTable",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      if (error.name === "AbortError") {
        commit("setLoading", {
          name: "getPaginatedIssuesForIssuesTable",
          value: false,
        });
        return;
      } else {
        dispatch(
          "error/raiseError",
          { name: "getPaginatedIssuesForIssuesTable", error },
          { root: true },
        );
      }
    }
    commit("setLoading", {
      name: "getPaginatedIssuesForIssuesTable",
      value: false,
    });
  },
  // Checks table data
  async getChecks({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getChecks", value: true });
      commit("setChecks", []);
      // Go to all places that use this to make sure turbine id is passed in as an object
      const turbine_id =
        typeof payload.turbineId === "string"
          ? parseInt(payload.turbineId)
          : payload.turbineId;
      const params = {
        group_by: "issue",
        get_losses_gains: true,
      };
      if (payload.component_type) {
        if (payload.component_type === "rotor_shaft_assembly") {
          params.component_type = "main_bearing";
        } else {
          params.component_type = payload.component_type;
        }
      }

      const res = await axios.get(`/turbines/${turbine_id}/issues`, {
        params,
      });

      if (res.status === 200) {
        // Assign turbine id to the results to validate that the checks belong to that turbine
        if (res.data.results.length > 0) {
          res.data.turbineId = turbine_id;
          res.data.component_type = payload.component_type;
          commit("setChecks", res.data);
        }
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getChecks",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getChecks", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getChecks", value: false });
  },
  async getConverterData({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getConverterData", value: true });
      commit("setConverterData", {});
      // Go to all places that use this to make sure turbine id is passed in as an object
      const turbine_id = payload.turbineId;

      const res = await axios.get(
        `/turbines/${turbine_id}/components/converter`,
      );

      if (res.status === 200) {
        if (!res.data) {
          res.data = {
            siteId: payload.siteId,
          };
        }
        commit("setConverterData", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getConverterData",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getConverterData", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getConverterData", value: false });
  },
  async getMainBearingData({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getMainBearingData", value: true });
      commit("setMainBearingData", {});
      const turbine_id = payload.turbineId;

      const res = await axios.get(
        `/turbines/${turbine_id}/components/main-bearing`,
      );

      if (res.status === 200) {
        if (!res.data) {
          res.data = {
            siteId: payload.siteId,
          };
        }
        // Assign turbine id to the results to validate that the checks belong to that turbine
        commit("setMainBearingData", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getMainBearingData",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getMainBearingData", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getMainBearingData", value: false });
  },
  async getSubsystemComponents({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getSubsystemComponents", value: true });
      commit("setSubsystemComponents", {});
      const turbine_id = payload.turbineId;
      let subsystemDefId = null;
      if (payload.subsystem.includes("rotor shaft")) {
        subsystemDefId = 1;
      } else if (payload.subsystem === "converter") {
        subsystemDefId = 2;
      } else {
        subsystemDefId = 3;
      }
      const params = {
        subsystem_def_id: subsystemDefId,
      };

      const res = await axios.get(`/turbines/${turbine_id}/components`, {
        params,
      });

      if (res.status === 200) {
        // Assign turbine id to the results to validate that the checks belong to that turbine
        commit("setSubsystemComponents", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getSubsystemComponents",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSubsystemComponents", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getSubsystemComponents", value: false });
  },
};
const mutations = {
  setTurbineData(state, payload) {
    state.turbineData = payload;
  },
  setChecks(state, payload) {
    state.checks = payload;
  },
  setPaginatedIssues(state, data) {
    state.paginatedIssuesForIssuesTable = data;
  },
  setPaginatedIssuesTotal(state, data) {
    state.paginatedIssuesTotal = data;
  },
  setConfirmedIssues(state, data) {
    const allConfirmedIssues = data.filter(
      (issue) =>
        issue.is_confirmed && !issue.is_dismissed && !issue.is_invalidated,
    );
    state.confirmedIssues = allConfirmedIssues;
  },
  setIssuesOpen(state, data) {
    state.issuesOpen = data;
  },
  setIssuesUnconfirmed(state, data) {
    state.issuesUnconfirmed = data;
  },
  setIssuesFixed(state, data) {
    state.issuesFixed = data;
  },
  setIssuesRequireAttention(state, data) {
    state.issuesRequireAttention = data;
  },
  setIssuesResolved(state, data) {
    state.issuesResolved = data;
  },
  resetAllTurbineData(state) {
    state.turbineData = {};
    state.checks = [];
    state.paginatedIssuesTotal = 0;
  },
  setConverterData(state, payload) {
    state.converterData = payload;
  },
  setMainBearingData(state, payload) {
    state.mainBearingData = payload;
  },
  setSubsystemComponents(state, payload) {
    state.subsystemComponents = payload;
  },
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setAbortControllerPaginatedIssues(state) {
    state.abortControllerPaginatedIssues = new AbortController();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
