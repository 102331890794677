import axios from "@/helpers/axiosAPI";

// initial state
const state = () => ({
  loading: {
    addNewOrg: false,
    getAllOrgs: false,
    updateOrg: false,
    allOrgs: false,
  },
  allOrgs: [],
  orgResponse: {},
});

// actions
const actions = {
  async getAllOrgs({ commit, dispatch, rootState }) {
    try {
      commit("setLoading", { name: "getAllOrgs", value: true });
      const params = {
        recursive: false,
        for_admin: true,
      };
      const res = await axios.get("/orgs", {
        params,
      });
      if (res.status === 200) {
        commit(
          "setAllOrgs",
          res.data?.sort((a, b) => {
            return a.name?.localeCompare(b.name);
          }),
        );
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getAllOrgs",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getAllOrgs", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getAllOrgs", value: false });
  },
  async addNewOrg({ commit, dispatch, rootState }, org) {
    try {
      commit("setLoading", { name: "addNewOrg", value: true });
      commit("setOrg", {});
      const body = {
        name: org,
      };
      const res = await axios.post(`/orgs`, body);
      if (res.status === 200) {
        commit("setOrg", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "addNewOrg",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "addNewOrg", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "addNewOrg", value: false });
  },
  async updateOrg({ commit, dispatch, rootState }, org) {
    try {
      commit("setLoading", { name: "updateOrg", value: true });
      commit("setOrg", {});

      const body = {
        org_id: org.id,
        name: org,
      };

      const res = await axios.put(`/orgs`, body);

      if (res.status === 200) {
        commit("setOrg", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "updateOrg",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "updateOrg", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "updateOrg", value: false });
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setOrg(state, payload) {
    state.orgResponse = payload;
  },
  setAllOrgs(state, data) {
    state.allOrgs = [...data];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
