import axios from "@/helpers/axiosAPI";

// initial state
const state = () => ({
  loading: {
    getAdminPermissions: false,
    createNewUser: false,
    getUserDetails: false,
    editUserDetails: false,
  },
  paginationData: [],
  adminPermissions: {},
  adminOrgs: [],
  adminSites: [],
  userDetails: {},
  permissions: [],
  editUserResponse: {},
  permissionCount: 0,
  createUserResponse: "",
  userEdited: false,
});

// actions
const actions = {
  async getAdminPermissions({ commit, dispatch, rootState }) {
    try {
      commit("setLoading", { name: "getAdminPermissions", value: true });

      const res = await axios.get("/user/admin");

      if (res.status === 200) {
        for (const perm of rootState.user.userData.permissions) {
          if (
            perm.entity_id === "*" &&
            perm.entity_type === "org" &&
            rootState.user.userData.is_internal
          ) {
            res.data.asterisk = true;
          } else res.data.asterisk = false;
        }

        commit("setAdminPermissions", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getAdminPermissions",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getAdminPermissions", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getAdminPermissions", value: false });
  },
  async createNewUser({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "createNewUser", value: true });
      const body = {
        email: payload.email,
        name_first: payload.name_first,
        name_last: payload.name_last,
        phone_number: payload.phone_number,
        time_zone: payload.time_zone,
        permissions: payload.permissions,
      };

      const res = await axios.post("/users", body);

      if (res.status === 200) {
        if (res.data.id && res.data.aws_sub) {
          res.data.type = "create";
          commit("setUserDetails", res.data);
          commit("setCreateUserResponse", res.data);
        }
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "createNewUser",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      if (error.response?.data?.detail[0] || error.response?.data?.detail) {
        dispatch(
          "error/raiseError",
          {
            name: "createNewUser",
            ...{
              value:
                "User can not be created due to missing data or authorization.",
            },
          },
          { root: true },
        );
      } else {
        dispatch(
          "error/raiseError",
          { name: "createNewUser", error },
          { root: true },
        );
      }
    }

    commit("setLoading", { name: "createNewUser", value: false });
  },
  async getUserDetails({ commit, dispatch }, userId) {
    try {
      commit("setLoading", { name: "getUserDetails", value: true });
      commit("setUserDetails", {});
      if (typeof userId === "string") {
        userId = parseInt(userId);
      }

      const res = await axios.get(`/users/${userId}`);

      if (res.status === 200) {
        commit("setUserDetails", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getUserDetails",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getUserDetails", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getUserDetails", value: false });
  },
  async editUserDetails({ commit, dispatch }, payload) {
    try {
      commit("setEditUserResponse", {});
      commit("setLoading", { name: "editUserDetails", value: true });
      const body = {
        email: payload.email,
        name_first: payload.name_first,
        name_last: payload.name_last,
        phone_number: payload.phone_number,
        time_zone: payload.time_zone,
        permissions: payload.permissions,
        is_active: payload.is_active,
      };

      const res = await axios.put(`/users/${payload.userId}`, body);

      if (res.status === 200) {
        res.data.from_create = true;
        commit("setUserDetails", res.data);
        commit("setEditUserResponse", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "editUserDetails",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "editUserDetails", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "editUserDetails", value: false });
  },
  clearUser({ commit }) {
    commit("clearUser");
  },
  resetCreateUserResponse({ commit }) {
    commit("setCreateUserResponse", false);
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setAdminPermissions(state, data) {
    if (data.orgs.length > 0) {
      state.adminOrgs = data.orgs;
      if (data.asterisk) {
        state.adminOrgs.unshift({ id: "*", name: "All orgs (*)" });
      }
    }
    if (data.sites.length > 0) {
      state.adminSites = data.sites;
    }
    state.adminPermissions = data;
  },
  setCreateUserResponse(state, data) {
    state.createUserResponse = data;
  },
  setUserDetails(state, data) {
    if (Object.keys(data).length > 0) {
      const permissions = data.simplified_permissions.map((perm) => {
        const permissionObj = {
          internal: perm.internal,
          level: perm.level,
          entity_type: perm.entity_type === "org" ? "Org" : "Site",
          entity_id: perm.entity_id === "*" ? ["*"] : perm.entity_id,
        };
        permissionObj.permKey =
          permissionObj.entity_id === "*"
            ? `${permissionObj.entity_type}_*`
            : `${permissionObj.entity_type}_${permissionObj.entity_id.join(
                "_",
              )}`;
        return permissionObj;
      });
      data.permissions = permissions;
      data.full_name = `${data.name_first} ${data.name_last}`;
    }
    // Either set to an empty object or with rearranged permissions
    state.userDetails = data;
  },
  setEditUserResponse(state, data) {
    state.editUserResponse = data;
  },
  updatePermissionCount(state, data) {
    state.permissionCount = data;
  },
  clearUser(state) {
    state.userDetails = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
