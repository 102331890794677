import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import { isChina } from "@/helpers/variables";
import { abortController } from "@/helpers/axiosAPI";
import {
  getNewLookPreference,
  pageMappings,
  reversePageMappings,
} from "../services/preferenceService";
import mixpanel from "mixpanel-browser";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const HomePage = () => import("@/pages/HomePage");
const GlobalDashboard = () => import("@/pages/GlobalDashboard");
const OrgDashboard = () => import("@/pages/OrgDashboard");
const NewOrgDashboard = () => import("@/pages/NewOrgDashboard");
const SiteDashboard = () => import("@/pages/SiteDashboard");
const NewSiteDashboard = () => import("@/pages/NewSiteDashboard");
const AllSiteComponents = () => import("@/pages/AllSiteComponents");
const SubsystemComponentDetail = () =>
  import("@/pages/SubsystemComponentDetail");
const TurbineDashboard = () => import("@/pages/TurbineDashboard");
const NewTurbineDashboard = () => import("@/pages/NewTurbineDashboard");
const Login = () => import("@/pages/Login");
const ForgotPassword = () => import("@/pages/ForgotPassword");
const IssueDetail = () => import("@/pages/IssueDetail");
const NewIssueDetail = () => import("@/pages/NewIssueDetail");
const CheckDetail = () => import("@/pages/CheckDetail");
const UserList = () => import("@/pages/UserList");
const UserManager = () => import("@/pages/UserManager");
const ResetPassword = () => import("@/pages/ResetPassword");
const NotFound = () => import("@/pages/NotFound");
const Glossary = () => import("@/pages/Glossary");
const NotificationManager = () => import("@/pages/NotificationManager");
const SitesAdmin = () => import("@/pages/SitesAdmin");
const SiteManager = () => import("@/pages/SiteManager");
const UserSettings = () => import("@/pages/UserSettings");
const ExpiredSubscription = () => import("@/pages/ExpiredSubscription");

Vue.use(Router);

function configRoutes() {
  return [
    {
      path: "/",
      component: TheContainer,
      children: [
        {
          path: "/",
          name: "HomePage",
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb() {
              return [
                {
                  to: {
                    name: "HomePage",
                  },
                  lastItem: true,
                },
              ];
            },
          },
          component: HomePage,
        },
        {
          path: "/global-dashboard",
          name: "GlobalDashboard",
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb() {
              return [
                {
                  to: {
                    name: "GlobalDashboard",
                  },
                  lastItem: true,
                },
              ];
            },
          },
          component: GlobalDashboard,
        },
        {
          path: "/orgs/:orgId",
          name: "OrgDashboard",
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              const orgId = route.params.orgId;

              return [
                {
                  to: {
                    name: "OrgDashboard",
                    params: { orgId },
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
          },
          component: OrgDashboard,
        },
        {
          path: "/orgs/new-org-dashboard/:orgId",
          name: "NewOrgDashboard",
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              const orgId = route.params.orgId;

              return [
                {
                  to: {
                    name: "NewOrgDashboard",
                    params: { orgId },
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
          },
          component: NewOrgDashboard,
        },
        {
          path: "/sites/:siteId",
          name: "SiteDashboard",
          props: true,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              const orgId = route.params.orgId;
              const siteId = route.params.siteId;
              return [
                {
                  to: {
                    name: "OrgDashboard",
                    params: { orgId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "SiteDashboard",
                    params: { orgId, siteId },
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
          },
          component: SiteDashboard,
        },
        {
          path: "/sites/new-site-dashboard/:siteId",
          name: "NewSiteDashboard",
          props: true,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              const orgId = route.params.orgId;
              const siteId = route.params.siteId;
              return [
                {
                  to: {
                    name: "OrgDashboard",
                    params: { orgId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "NewSiteDashboard",
                    params: { orgId, siteId },
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
          },
          component: NewSiteDashboard,
        },
        {
          path: "/sites/:siteId/:componentName",
          name: "AllSiteComponents",
          props: true,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              const orgId = route.params.orgId;
              const siteId = route.params.siteId;
              const componentName = route.params.componentName;

              return [
                {
                  to: {
                    name: "OrgDashboard",
                    params: { orgId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "SiteDashboard",
                    params: { orgId, siteId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "AllSiteComponents",
                    params: { orgId, siteId, componentName },
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
          },
          component: AllSiteComponents,
        },
        {
          path: "/sites/:siteId/checks/:issueDefId",
          name: "Check Detail",
          props: true,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              const siteId = route.params.siteId;
              const issueDefId = route.params.issueDefId;
              return [
                {
                  to: {
                    name: "CheckDetail",
                    params: { siteId, issueDefId },
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
          },
          component: CheckDetail,
        },
        {
          path: "/turbines/:turbineId",
          name: "TurbineDashboard",
          props: true,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              const orgId = route.params.orgId;
              const siteId = route.params.siteId;
              const turbineId = route.params.turbineId;

              return [
                {
                  to: {
                    name: "OrgDashboard",
                    params: { orgId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "SiteDashboard",
                    params: { orgId, siteId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "TurbineDashboard",
                    params: { orgId, siteId, turbineId },
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
          },
          component: TurbineDashboard,
        },
        {
          path: "/turbines/new-turbine-dashboard/:turbineId",
          name: "NewTurbineDashboard",
          props: true,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              const orgId = route.params.orgId;
              const siteId = route.params.siteId;
              const turbineId = route.params.turbineId;

              return [
                {
                  to: {
                    name: "NewOrgDashboard",
                    params: { orgId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "NewSiteDashboard",
                    params: { orgId, siteId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "NewTurbineDashboard",
                    params: { orgId, siteId, turbineId },
                    query: true,
                  },
                },
              ];
            },
          },
          component: NewTurbineDashboard,
        },
        {
          path: "/turbines/:turbineId/:componentName",
          name: "SubsystemComponentDetail",
          props: true,
          meta: {
            general: false,
            requiresAuth: true,
          },
          component: SubsystemComponentDetail,
        },
        {
          path: "/turbine-issues/:issueId",
          name: "IssueDetail",
          props: true,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              const orgId = route.params.orgId;
              const siteId = route.params.siteId;
              const turbineId = route.params.turbineId;
              const issueId = route.params.issueId;

              return [
                {
                  to: {
                    name: "OrgDashboard",
                    params: { orgId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "SiteDashboard",
                    params: { orgId, siteId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "TurbineDashboard",
                    params: { orgId, siteId, turbineId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "IssueDetail",
                    params: { orgId, siteId, turbineId, issueId },
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
          },
          component: IssueDetail,
        },
        /* Route to the new issue detail page. TODO: delete this and replace old issue detail page with new one */
        {
          path: "/turbine-issues/new-issue-page/:issueId",
          name: "NewIssueDetail",
          props: true,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              const orgId = route.params.orgId;
              const siteId = route.params.siteId;
              const turbineId = route.params.turbineId;
              const issueId = route.params.issueId;

              return [
                {
                  to: {
                    name: "OrgDashboard",
                    params: { orgId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "SiteDashboard",
                    params: { orgId, siteId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "TurbineDashboard",
                    params: { orgId, siteId, turbineId },
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "NewIssueDetail",
                    params: { orgId, siteId, turbineId, issueId },
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
          },
          component: NewIssueDetail,
        },
        {
          path: "/notifications",
          name: "Notifications",
          component: NotificationManager,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              return [
                {
                  to: {
                    name: "Notifications",
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
          },
        },
        {
          path: "/admin/sites",
          name: "Sites",
          component: SitesAdmin,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              return [
                {
                  to: {
                    name: "Sites",
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
            permissions: [
              {
                adminOnly: true,
                internalOnly: true,
                redirect: "/",
              },
            ],
          },
        },
        {
          path: "/admin/sites/new",
          name: "NewSite",
          component: SiteManager,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              return [
                {
                  to: {
                    name: "NewSite",
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
            permissions: [
              {
                adminOnly: true,
                internalOnly: true,
                redirect: "/",
              },
            ],
          },
        },
        {
          path: "/admin/sites/:siteId",
          name: "EditSite",
          component: SiteManager,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              return [
                {
                  to: {
                    name: "EditSite",
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
            permissions: [
              {
                adminOnly: true,
                internalOnly: true,
                redirect: "/",
              },
            ],
          },
        },
        {
          path: "/admin/users",
          name: "Users",
          component: UserList,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              return [
                {
                  to: {
                    name: "Users",
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
            permissions: [
              {
                adminOnly: true,
                internalOnly: false,
                redirect: "/",
              },
            ],
          },
        },
        {
          path: "/admin/users/:userId",
          name: "UserDetail",
          component: UserManager,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              const userId = route.params.userId;

              return [
                {
                  to: {
                    name: "Users",
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "UserDetail",
                    params: { userId },
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
            permissions: [
              {
                adminOnly: true,
                internalOnly: false,
                redirect: "/",
              },
            ],
          },
        },
        {
          path: "/admin/users/new",
          name: "NewUser",
          component: UserManager,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb(route) {
              return [
                {
                  to: {
                    name: "NewUser",
                    query: route.query,
                  },
                },
                {
                  to: {
                    name: "Users",
                    query: route.query,
                  },
                  lastItem: true,
                },
              ];
            },
            permissions: [
              {
                adminOnly: true,
                internalOnly: false,
                redirect: "/",
              },
            ],
          },
        },
        {
          path: "/settings",
          name: "Settings",
          component: UserSettings,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb() {
              return [
                {
                  text: "Settings",
                },
              ];
            },
          },
        },
        {
          path: "/glossary",
          name: "Glossary",
          component: Glossary,
          meta: {
            general: false,
            requiresAuth: true,
            breadCrumb() {
              return [
                {
                  text: "Glossary",
                },
              ];
            },
          },
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/forgotpassword",
      name: "ForgotPassword",
      component: ForgotPassword,
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
    },
    { path: "*", redirect: "/404" },
    {
      path: "/404",
      name: "NotFound",
      component: NotFound,
    },
    {
      path: "/expired-subscription",
      name: "ExpiredSubscription",
      component: ExpiredSubscription,
    },
    {
      path: "/org/:orgId",
      redirect: "/orgs/:orgId",
    },
    {
      path: "/site/:siteId",
      redirect: "/sites/:siteId",
    },
    {
      path: "/turbine/:turbineId",
      redirect: "/turbines/:turbineId",
    },
    {
      path: "/issue/:issueId",
      redirect: "/turbine-issues/:issueId",
    },
  ];
}

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

router.beforeEach(async (to, from, next) => {
  if (from.name !== "Login" && from.path !== "/") {
    abortController.abort();
  }

  let token = localStorage.getItem("id_token");
  if (token == null) token = localStorage.getItem("access_token");
  let userData = store.state.user.userData;

  // Redirect to login if we have no token
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      router.push({ name: "Login" }).catch((err) => {
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location",
          )
        ) {
          console.error(err);
        }
      });
      return;
    }
  }

  // Check if the user data is already fetched
  if (!userData && token) {
    const getUserDataFuncs = store._actions["user/getUserData"];
    if (getUserDataFuncs && getUserDataFuncs[0]) {
      await getUserDataFuncs[0]();
    }
    userData = store.state.user.userData;
  }

  // Redirect handler for '/' path
  if (to.fullPath === "/") {
    if (!userData) {
      router.push({ name: "Login" }).catch((err) => {
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location",
          )
        ) {
          console.error(err);
        }
      });
      return;
    }
  }

  // If an error occured in a previous page, clear errors before going to another page
  if (store.state.error.errors.length > 0) {
    await store.dispatch("error/clearAllErrors");
  }

  if (to.meta && to.meta.permissions) {
    const permissions = to.meta.permissions;

    if (permissions && permissions.length > 0) {
      for (let i = 0; i < permissions.length; i++) {
        const permission = permissions[i];

        // Redirect non-admin user if navigating to admin page
        if (permission.adminOnly && !userData?.is_admin) {
          router.push({ path: permission.redirect });
        }

        // Redirect non-internal user if navigating to internal page
        if (permission.internalOnly && !userData?.is_internal) {
          router.push({ path: permission.redirect });
        }
      }
    }
  }

  const isNewLookEnabled = getNewLookPreference();
  const targetRouteName = pageMappings[to.name]; // Target for old to new
  const reverseTargetRouteName = reversePageMappings[to.name]; // Target for new to old

  if (
    ((to.name === "SiteDashboard" && from.name === "SiteDashboard") ||
      from.name === "SiteDashboard") &&
    store.state.site.isUploading
  ) {
    if (!window.confirm("Your uploads will be canceled, leave anyway?")) {
      return;
    }
  } else if (isNewLookEnabled && targetRouteName) {
    // Redirecting from old to new
    next({ name: targetRouteName, params: to.params });
  } else if (!isNewLookEnabled && reverseTargetRouteName) {
    // Redirecting from new to old
    next({ name: reverseTargetRouteName, params: to.params });
  } else {
    mixpanel.track_pageview({ page_name: to.name });
    next();
  }
});

router.afterEach((to, from) => {
  // On initial load or page refresh, a loading animation will show
  if (from.name === null) {
    store.commit("app/updateWaiting", false);
  }
});

export default router;
