const _site = {
  site_id: 30,
  site_name: "Dewas",
  location: "Madhya Pradesh, India",
  time_zone: "Asia/Kolkata",
  latitude: 42.820339,
  longitude: -78.843399,
  turbines: [
    {
      turbine_id: 623,
      turbine_name: "REN-DEW-AVP02",
      latitude: 41.931828,
      longitude: -74.011108,
      date_found: "2021-03-21",
      issues_that_need_attention: 4,
      total_entegy_loss: 320,
      aep_loss: 320,
    },
    {
      turbine_id: 624,
      turbine_name: "REN-DEW-AVP02_1",
      latitude: 43.010265,
      longitude: -74.383682,
      date_found: "2021-04-01",
      issues_that_need_attention: 2,
      total_entegy_loss: 120,
      aep_loss: 320,
    },
    {
      turbine_id: 625,
      turbine_name: "REN-DEW-AVP02_2",
      latitude: 43.052845,
      longitude: -74.363762,
      date_found: "2021-04-02",
      issues_that_need_attention: 2,
      total_entegy_loss: 45,
      aep_loss: 320,
    },
    {
      turbine_id: 626,
      turbine_name: "REN-DEW-AVP02_3",
      latitude: 41.292282,
      longitude: -73.930725,
      date_found: "2021-04-21",
      issues_that_need_attention: 1,
      total_entegy_loss: 59,
      aep_loss: 320,
    },
  ],
};

const _portfolioIssues = {
  issues: [
    {
      id: 101,
      name: "Region3 power below spec",
      status: "Actionable/in-progress",
      date_found: "2021-03-13",
      site: "Milford1-Clipper",
      turbine: "T1",
      model: "Cliper C89/2500w",
      subscription: "find/fix/measure",
      total_entegy_loss: 320,
      aep_loss: 320,
    },
    {
      id: 102,
      name: "Region3 power below spec",
      status: "Pending",
      date_found: "2021-04-23",
      site: "Milford1-GE",
      turbine: "T1",
      model: "GE3.x-103/2500w",
      subscription: "find/fix/measure",
      total_entegy_loss: 574,
      aep_loss: 574,
    },
    {
      id: 103,
      name: "Region3 pitch peer outlier",
      status: "Not-actionable",
      date_found: "2021-09-23",
      site: "Milford1-GE",
      turbine: "T23",
      model: "GE3.x-103/2500w",
      subscription: "find",
      total_entegy_loss: 211,
      aep_loss: 211,
    },
  ],
};

export default {
  getSiteById() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(_site);
      }, 100);
    });
  },
  getPortfolioIssues() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(_portfolioIssues);
      }, 1000);
    });
  },
};
