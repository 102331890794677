<template>
  <v-app>
    <div v-if="appWaiting">
      <RefreshPageLoader />
    </div>
    <router-view app v-else></router-view>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RefreshPageLoader from "./components/RefreshPageLoader";
import mixpanel from "mixpanel-browser";

let mixpanelToken = null;
const url = new URL(window.location.href);
if (
  process.env.NODE_ENV === "development" ||
  url.host === "app-dev.windesco.com"
) {
  mixpanelToken = "fc582411e02ced33494931e3eb6ca70f";
} else if (process.env.NODE_ENV === "production") {
  mixpanelToken = "cc083618087edd51ad8d8ea86c872ed3";
}
mixpanel.init(mixpanelToken, { track_pageview: "url-with-path" });

export default {
  name: "App",
  components: {
    RefreshPageLoader,
  },
  computed: {
    ...mapState({
      appWaiting: (state) => state.app.waiting,
      userData: (state) => state.user.userData,
    }),
  },
  created() {
    this.updateWaiting(true);
  },
  methods: {
    ...mapActions({
      updateWaiting: "app/updateWaiting",
      getUserData: "user/getUserData",
    }),
    // If user theme is auto, set to OS theme. Or if user theme is not set default to auto.
    setVuetifyColorTheme() {
      if (this.userData?.settings?.theme === "auto") {
        this.$vuetify.theme.dark =
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches;
      } else if (this.userData?.settings?.theme === "dark") {
        this.$vuetify.theme.dark = true;
      } else if (this.userData?.settings?.theme === "light") {
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark =
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches;
      }
    },
  },
  watch: {
    userData: {
      immediate: true,
      handler(data) {
        if (data?.settings?.theme) {
          this.setVuetifyColorTheme();
        }
      },
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
