import axios from "@/helpers/axiosAPI";
import router from "@/router";

// initial state
const state = () => ({
  loading: {
    getOrgs: false,
  },
  sidebarMinimize: false,
  orgs: [],
  waiting: false,
  showBurger: false,
  burgerData: null,
  clickCount: 0,
});

// actions
const actions = {
  toggleSidebarMinimize({ commit }, value) {
    commit("setSidebarMinimize", value);
  },
  async getOrgs({ commit, dispatch, rootState }) {
    try {
      commit("setLoading", { name: "getOrgs", value: true });
      // Sorting functions
      const sortByName = (a, b) => a.name?.localeCompare(b.name);
      const sortByTurbineName = (a, b) =>
        a.turbine_name?.localeCompare(b.turbine_name, undefined, {
          numeric: true,
        });
      const res = await axios.get("/orgs");

      // Sorting orgs and sites alphabetically; turbines alphanumerically
      if (res.status === 200) {
        let breakCheck = false;
        res.data.sort(sortByName);
        for (const org of res.data) {
          org.sites.sort(sortByName);
          org.active = false;
          for (const site of org.sites) {
            site.turbines.sort(sortByTurbineName);
            if (
              router.currentRoute.path.includes("site") &&
              router.currentRoute.params.siteId == site.id
            ) {
              org.active = true;
              breakCheck = true;
              break;
            }
            site.active = false;
            for (const turbine of site.turbines) {
              turbine.fullName = `Turbine ${turbine.shortname}`;
              if (
                router.currentRoute.path.includes("turbine") &&
                router.currentRoute.params.turbineId == turbine.id
              ) {
                org.active = true;
                site.active = true;
              }
              //  Checking if on issue detail page to activate its turbine in the sidebar
              if (
                router.currentRoute.path.includes("issue") &&
                turbine.id == rootState.issueDetail.issueTurbineId
              ) {
                org.active = true;
                site.active = true;
                turbine.active = true;
              }
            }
          }
          if (breakCheck) break;
        }

        commit("setOrgs", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getOrgs",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch("error/raiseError", { name: "getOrgs", error }, { root: true });
    }

    commit("setLoading", { name: "getOrgs", value: false });
  },
  updateWaiting({ commit }, value) {
    commit("updateWaiting", value);
  },
  updateShowBurger({ commit }, value) {
    commit("updateShowBurger", value);
  },
  updateBurgerData({ commit }, value) {
    commit("updateBurgerData", value);
  },
  incrementClickCount({ commit }) {
    commit("updateClickCount");
  },
  setClickCountByLocalStorage({ commit }, value) {
    commit("setClickCount", value);
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setSidebarMinimize(state, value) {
    state.sidebarMinimize = value;
  },
  setOrgs(state, data) {
    state.orgs = [...data];
  },
  updateWaiting(state, value) {
    state.waiting = value;
  },
  updateShowBurger(state, value) {
    state.showBurger = value;
  },
  updateBurgerData(state, value) {
    state.burgerData = value;
  },
  updateClickCount(state) {
    state.clickCount++;
    // Store value in local storage in case of page refreshes
    localStorage.setItem("clickCount", state.clickCount);
  },
  setClickCount(state, value) {
    if (value) {
      state.clickCount = value;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
