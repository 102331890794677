var showdown = require("showdown");

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const translateSubscription = (subs) => {
  if (!subs) {
    return "Unknown";
  }

  if (subs.length === 0) {
    return "Empty";
  }

  if (subs.length === 1) {
    return `${capitalizeFirstLetter(subs[0])} only`;
  }

  let str = "";

  for (let i = 0; i < subs.length; i++) {
    if (i === 0) {
      str = capitalizeFirstLetter(subs[i]);
    } else if (i === subs.length - 1) {
      str += ` and ${subs[i]}`;
    } else {
      str += `, ${subs[i]}`;
    }
  }

  return str;
};

export const formatFileSize = (bytes, decimalPoint) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getDocumentIconName = (extension) => {
  switch (extension) {
    case "xls":
      return "xls";

    case "pdf":
      return "pdf";

    default:
      return "image";
  }
};

export const generateQuery = (queryName, queryValue) => {
  const query = {};

  if (queryValue) {
    query[queryName] = queryValue;
  }

  return query;
};

export const groupBy = (items, key) => {
  const itemsObj = items.reduce((result, item) => {
    return {
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    };
  }, {});

  return alphabetizeObject(itemsObj);
};

export const alphabetizeObject = (obj) =>
  Object.keys(obj)
    .sort()
    .reduce((result, key) => ((result[key] = obj[key]), result), {});

// By: Dr. Derek Austin, Oct 7, 2019
// https://javascript.plainenglish.io/how-to-deep-copy-objects-and-arrays-in-javascript-7c911359b089
export const deepCopyFunction = (inObject) => {
  let outObject, value, key;

  if (typeof inObject !== "object" || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopyFunction(value);
  }

  return outObject;
};

export const generateQueryString = (query) => {
  if (Object.keys(query).length === 0) {
    return "";
  }

  const qs = Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join("&");

  return `?${qs}`;
};

export const getFileNameFromURI = (uri) => {
  if (!uri) {
    return "";
  }

  let fileName = uri.split("/").pop();

  if (!fileName) {
    return "";
  }

  fileName = fileName.split("?").shift();

  if (!fileName) {
    return "";
  }

  return fileName;
};

export const getFirstNames = (name) => {
  if (!name) {
    return "N/A";
  }

  const [first, last] = name.split(" ");

  if (first && last) {
    return `${first.charAt(0).toUpperCase()}${last.charAt(0).toUpperCase()}`;
  }

  if (first) {
    return first.charAt(0).toUpperCase();
  }

  if (last) {
    return last.charAt(0).toUpperCase();
  }
};

export const isEmptyObject = (obj) => Object.keys(obj).length === 0;

export const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};

// Returns first and last initial: John Doe Smith => JS
// REGEX https://stackoverflow.com/a/63763497
export const userInitials = (name) => {
  if (name) {
    return name
      .match(/(\b\S)?/g)
      .join("")
      .match(/(^\S|\S$)?/g)
      .join("")
      .toUpperCase();
  } else {
    return "";
  }
};

// Add 's' to string if count is 1
export const pluralize = (count, word, suffix = "s") => {
  return `${word}${count === 1 ? suffix : ""}`;
};

// Sidebar search filtering for orgs, sites, and turbines
export const deepFilter = (items, value) => {
  let result = [];
  const searchValue = value?.toLowerCase();

  for (const item of items) {
    if (
      item.name?.toLowerCase().includes(searchValue) ||
      (item.fullName && item.fullName?.toLowerCase().includes(searchValue))
    ) {
      result.push(item);
    } else {
      const property = item.sites ? "sites" : item.turbines ? "turbines" : null;
      if (property) {
        const subItems = deepFilter(item[property], searchValue);
        if (subItems.length) {
          item.active = true;
          item[property] = subItems;
          result.push(item);
        }
      }
    }
  }

  return result;
};

/* Rounding function that will round to nearest decimal, 10s, 100s, etc.
   1 for one decimal place, 2 for 2 and so on...
   0 for nearest whole number, -1 for 10s, -2 for 100s etc...
   returns string version of altered value
*/
export const roundToString = (value, precision) => {
  // If this can't be interpreted as a number, return as-is
  if (isNaN(parseFloat(value))) {
    return value;
  }
  if (typeof value === "string" && value.includes(",")) {
    value = value.replace(",", "");
  }
  let multiplier = Math.pow(10, precision || 0);
  let s = Math.round(value * multiplier) / multiplier;
  if (precision >= 0) {
    s = s.toFixed(precision);
    return s.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const findFileExt = (filename) => {
  let lastIndex = filename.lastIndexOf(".");
  let extension = filename.substring(lastIndex + 1);
  let ext = "";

  if (extension.includes("?")) {
    let extSplit = extension.split("?");
    ext = extSplit[0];
    return ext;
  } else if (extension.includes("&")) {
    let extSplit = extension.split("&");
    ext = extSplit[0];
    return ext;
  } else {
    return extension;
  }
};

// Remove duplicate objects from array
export const removeDuplicates = (objArray, property) => {
  const uniqueItemsSet = new Set();

  const uniqueSites = objArray.filter((obj) => {
    const presentInSet = uniqueItemsSet.has(obj[property]);
    uniqueItemsSet.add(obj[property]);
    return !presentInSet;
  });

  return uniqueSites;
};

// https://www.webiny.com/blog/upload-files-to-aws-s3-using-pre-signed-post-data-and-a-lambda-function-7a9fb06d56c1
export const uploadFileToS3 = (presignedPostData, file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });
    // Actual file has to be appended last.
    formData.append("file", file);
    const xhr = new XMLHttpRequest();
    xhr.open("POST", presignedPostData.url, true);
    xhr.send(formData);
    xhr.onload = function () {
      this.status === 204 ? resolve() : reject(this.responseText);
    };
  });
};

// Returns the string parameter with added html according to the string's markdown syntax
export const processMarkdown = (text) => {
  const listIndentationRegex = /(<ul>)/g;
  // Escape underscores within LaTeX expressions because showdown would convert them to <em> tags
  text = text.replace(/\$(.*?)\$/g, (match, latex) => {
    const escapedLatex = latex.replace(/_/g, "\\_");
    return `$${escapedLatex}$`;
  });

  let html = null;
  let converter = new showdown.Converter({ noPants: true });
  html = converter.makeHtml(text);
  html = html.replace(listIndentationRegex, "<ul style='margin-left: 25px;'>");
  return html;
};

export const formatErrors = (errorsObject) => {
  if (typeof errorsObject === "object" && errorsObject) {
    // Remove nullish entries
    const errorsObj = Object.entries(errorsObject).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {},
    );
    // Format all errors in the object to a string
    if (Object.keys(errorsObj).length > 0) {
      let errors = "";
      for (const [key, value] of Object.entries(errorsObj)) {
        errors += `${key}: ${
          value.data ? JSON.stringify(value.data) : value
        }\n`;
      }
      return errors;
    }
    return "";
  } else {
    return "";
  }
};

// Round AEP percent values to 1 decimal if below 0.05
export const roundAepPct = (val) => {
  if (val === null) {
    return "0.0";
  }
  val = parseFloat(val);
  if (val >= 0.05) {
    return val.toFixed(1);
  } else if (val >= 0.005) {
    return val.toFixed(2);
  } else if (val >= 0.0005) {
    return val.toFixed(3);
  } else {
    return "0.0";
  }
};

export const setUrlParams = (url, params, label) => {
  if (Object.keys(params).length > 0) {
    // Set each search param's name to the param property and value to the param property's value
    for (const key in params) {
      // Handle when a param value is an array
      if (Array.isArray(params[key]) && label) {
        if (params[key].length > 0) {
          for (const item of params[key]) {
            url.searchParams.append(label, item);
          }
        }
      } else {
        url.searchParams.set(key, params[key]);
      }
    }
  }
  return url;
};

export const setUploadType = (kind) => {
  switch (kind) {
    case "hf":
      return "High frequency";
    case "10m":
      return "10 minute";
    case "event":
      return "Event";
    case "parameter":
      return "Parameters";
    case "met":
      return "Met";
  }
};

export const getFile = (fileEntry) => {
  try {
    return new Promise((resolve, reject) => fileEntry.file(resolve, reject));
  } catch (error) {
    console.error(error);
  }
};

export const getDashboardTabs = (dashboard, leftmostTab) => {
  let tabs = [];
  let removedTabItem = {};
  const reorderTabs = (tabs, leftmostTab) => {
    let leftmostIndex = null;
    for (const tab of tabs) {
      if (tab.value === leftmostTab) {
        leftmostIndex = tabs.indexOf(tab);
        break;
      }
    }
    removedTabItem = tabs.splice(leftmostIndex, 1)[0];
    tabs.unshift(removedTabItem);
    return tabs;
  };
  switch (dashboard) {
    case "home":
      tabs = [
        { label: "Checks", value: "checks" },
        { label: "Orgs", value: "orgs" },
        { label: "Sites", value: "sites" },
        { label: "OEMs", value: "oems" },
        { label: "Issues", value: "issues" },
      ];
      break;
    case "orgs":
      tabs = [
        { label: "Checks", value: "checks" },
        { label: "Sites", value: "sites" },
        { label: "OEMs", value: "oems" },
        { label: "Issues", value: "issues" },
      ];
      break;
    case "sites":
      tabs = [
        { label: "Checks", value: "checks" },
        { label: "Turbines", value: "turbines" },
        { label: "Issues", value: "issues" },
        { label: "Documents", value: "documents" },
        { label: "Data", value: "data" },
        { label: "Analytics", value: "site_analytics" },
      ];
      break;
    case "turbines":
      tabs = [
        { label: "Checks", value: "checks" },
        { label: "Issues", value: "issues" },
      ];
      break;
  }

  return reorderTabs(tabs, leftmostTab);
};
// This method is used for the analytics tab on site dashboards
export const compareTwoArraysForEquality = (array1, array2) => {
  let array1All = false;
  let array2All = false;
  let array1Values = [];
  let array2Values = [];

  // If either array has objects for elements, create flat array with just the element values
  if (array1[0]?.text) {
    for (const item of array1) {
      array1Values.push(item.value);
    }
  } else {
    array1Values = array1;
  }
  if (array2[0]?.text) {
    for (const item of array2) {
      array2Values.push(item.value);
    }
  } else {
    array2Values = array2;
  }

  for (const item of array1Values) {
    if (item === "all_turbines") {
      array1All = true;
      break;
    }
  }
  for (const item of array2Values) {
    if (item === "all_turbines") {
      array2All = true;
      break;
    }
  }

  if (array1All !== array2All) {
    return false;
  }

  // If neither array contains the all option, the flat arrays need to be compared for differences
  if (!array1All && !array2All) {
    if (array1Values.length !== array2Values.length) {
      return false;
    } else {
      const sortedArray1 = array1Values.sort();
      const sortedArray2 = array2Values.sort();
      if (JSON.stringify(sortedArray1) !== JSON.stringify(sortedArray2)) {
        return false;
      }
    }
  }

  return true;
};

export const formatTimestamp = (timestamp) => {
  if (timestamp && timestamp.includes("T")) {
    const splitTimestamp = timestamp.split("T");
    return splitTimestamp[0] + " " + splitTimestamp[1];
  } else {
    return timestamp;
  }
};

const applyDisplayFormatting = (name) => {
  return name.replace(/[_-]/g, " ");
};

const applyRouteFormatting = (name) => {
  return name.replace(/\s+/g, "-");
};

const applyRegularPluralization = (name) => {
  if (name.endsWith("y")) {
    return name.slice(0, -1) + "ies";
  } else if (
    name.endsWith("s") ||
    name.endsWith("x") ||
    name.endsWith("ch") ||
    name.endsWith("sh")
  ) {
    return name + "es";
  } else {
    return name + "s";
  }
};

export const transformName = (name, action, pluralize) => {
  const irregularPlurals = {
    gearbox: "gearboxes",
    pitch: "pitch",
    rotor_shaft_assembly: "rotor-shaft assemblies",
  };
  let transformedName = name;
  transformedName = applyDisplayFormatting(transformedName);
  if (pluralize) {
    if (irregularPlurals[name]) {
      transformedName = irregularPlurals[name];
    } else {
      transformedName = applyRegularPluralization(transformedName);
    }
  }
  if (action === "display") {
    if (transformedName.includes("rotor shaft")) {
      transformedName = "rotor-shaft assembly";
    }
    return transformedName.charAt(0).toUpperCase() + transformedName.slice(1);
  } else if (action === "route") {
    transformedName = applyRouteFormatting(transformedName);
    return transformedName;
  } else if (action === "router-link") {
    const words = transformedName.split(" ");
    transformedName = words
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join("");
    return transformedName;
  }
};
