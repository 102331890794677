import axios from "@/helpers/axiosAPI";
import { uploadFileToS3 } from "@/helpers/functions";

// initial state
const state = () => ({
  loading: {
    addComment: false,
    getComments: false,
  },
  comments: [],
});

// actions
const actions = {
  async addComment(
    { commit, dispatch, rootState },
    { issueId, comment, attachments },
  ) {
    try {
      commit("setLoading", { name: "addComment", value: true });
      let s3attachments = [];

      if (attachments.length > 0) {
        for (const attachment of attachments) {
          const params = {
            turbine_issue_id: +issueId,
            name: attachment.name,
            kind: "comment",
          };

          const attachmentResponse = await axios.post(
            `/turbine-issues/${issueId}/attachments`,
            params,
          );
          if (attachmentResponse.status === 200) {
            s3attachments.push(attachmentResponse.data.attachment);
            await uploadFileToS3(attachmentResponse.data.post, attachment);
          } else {
            dispatch(
              "error/raiseError",
              {
                name: "addComment",
                ...{
                  code: res.status,
                  message: "Error attachment API call",
                  value: "unknown error",
                },
              },
              { root: true },
            );
          }
        }
      }

      const res = await axios.post(`/turbine-issues/${issueId}/comments`, {
        comment: comment,
        attachments: s3attachments,
      });

      if (res.status !== 200) {
        dispatch(
          "error/raiseError",
          {
            name: "addComment",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "addComment", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "addComment", value: false });
  },
  async getComments({ commit, dispatch }, { issueId }) {
    try {
      commit("setLoading", { name: "getComments", value: true });

      const res = await axios.get(`/turbine-issues/${issueId}/comments`);
      if (res.status === 200) {
        commit("updateComments", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getComments",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getComments", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getComments", value: false });
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  updateComments(state, data) {
    state.comments = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
