import axios from "@/helpers/axiosAPI";
import { uploadFileToS3, processMarkdown } from "@/helpers/functions";

// initial state
const state = () => ({
  loading: {
    fetchIssueDetailData: false,
    getIssueStatusData: false,
    getTurbineModDefs: false,
    updateIssueStatus: false,
    getLossesGains: false,
    getSuggestedRecommendation: false,
    editTurbineIssueDetails: false,
    editIssueEvidence: false,
  },
  issueData: null,
  definition: null,
  issueStatuses: [],
  statusHistory: [],
  latestStatus: {},
  lossesGains: null,
  updateIssueStatusResponse: null,
  turbineModDefs: [],
  recommendationSelection: null,
  cancel: false,
  suggestedRecommendation: null,
  lossModelDetailsHTML: null,
  issueTurbineId: null,
  statusHistoryCreatedTs: null,
});

// actions
const actions = {
  async updateIssueTurbineId({ commit }, turbineId) {
    commit("setIssueTurbineId", turbineId);
  },
  async fetchIssueDetailData({ commit, dispatch }, { issueId }) {
    commit("setLoading", {
      name: "fetchIssueDetailData",
      value: true,
    });
    try {
      commit("setIssueData", null);

      const res = await axios.get(`/turbine-issues/${+issueId}`);

      if (res.status === 200) {
        commit("setIssueData", res.data);
        commit("setIssueTurbineId", res.data.turbine_id);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "fetchIssueDetailData",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "fetchIssueDetailData", error },
        { root: true },
      );
    }

    commit("setLoading", {
      name: "fetchIssueDetailData",
      value: false,
    });
  },
  async getIssueStatusData({ commit, dispatch }, { issueId }) {
    try {
      commit("setLoading", {
        name: "getIssueStatusData",
        value: true,
      });

      const res = await axios.get(`/turbine-issues/${+issueId}/status`);

      if (res.status === 200) {
        commit("setIssueStatusData", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getIssueStatusData",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getIssueStatusData", error },
        { root: true },
      );
    }

    setTimeout(() => {
      commit("setLoading", {
        name: "getIssueStatusData",
        value: false,
      });
    }, 1000);
  },
  async getTurbineModDefs({ commit, dispatch }) {
    try {
      const res = await axios.get("/turbine-mod-defs");

      if (res.status === 200) {
        commit("setTurbineModDefs", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getTurbineModDefs",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getTurbineModDefs", error },
        { root: true },
      );
    }
  },
  async updateIssueStatus({ commit, dispatch, rootState }, payload) {
    try {
      commit("setLoading", {
        name: "updateIssueStatus",
        value: true,
      });
      let body = {};
      let attachments = [];
      body = {
        turbine_issue_id: +payload.issueId,
        turbine_issue_status_def_id: payload.statusDefId,
      };

      if (payload.comment) {
        body.comment = payload.comment.trim();
        if (!body.comment.endsWith(".")) {
          body.comment = `${body.comment}.`;
        }
      }
      if (payload.ts) {
        body.ts = payload.ts;
      }
      if (payload.turbine_mod_id) {
        body.turbine_mod_id = payload.turbine_mod_id;
      }
      if (payload.mod) {
        body.mod = {
          turbine_mod_def_id: payload.mod.id,
          turbine_recommendation_id: payload.turbineRecommendationId,
        };
        if (payload.mod.requires_value) {
          body.mod.value = +payload.value;
        }
        if (payload.modNotes) {
          body.mod.notes = payload.modNotes;
        }
      }
      if (payload.turbineRecommendationId) {
        body.turbine_recommendation_id = payload.turbineRecommendationId;
      }
      if (payload.recommendation && !payload.turbineRecommendationId) {
        body.recommendation = {
          turbine_mod_def_id: payload.modId,
          notes: payload.recommendation,
        };
        if (payload.value !== undefined && payload.value !== null) {
          body.recommendation.value = payload.value;
        }
      }
      if (payload.attachment) {
        for (const attachment of payload.attachment) {
          const params = {
            user_aws_sub: rootState.user.awsSub,
            turbine_issue_id: +payload.issueId,
            name: attachment.name,
            kind: payload.kind,
          };

          const attachmentResponse = await axios.post(
            "/turbine-issue/attachment",
            params,
          );
          if (attachmentResponse.status === 200) {
            attachments.push(attachmentResponse.data.attachment);
            await uploadFileToS3(attachmentResponse.data.post, attachment);
          } else {
            dispatch(
              "error/raiseError",
              {
                name: "updateIssueStatus",
                ...{
                  code: res.status,
                  message: "Error API call",
                  value: "unknown error",
                },
              },
              { root: true },
            );
          }
        }
        body.attachments = attachments;
      }
      if (payload.previousAttachments) {
        if (body.attachment) {
          body.attachments.push(...payload.previousAttachments);
        } else {
          body.attachments = payload.previousAttachments;
        }
      }
      if (payload.superseded_by_turbine_issue_id) {
        body.superseded_by_turbine_issue_id =
          payload.superseded_by_turbine_issue_id;
        body.ts = null;
        body.turbine_mod_id = null;
        body.attachment = [{}];
      }

      const res = await axios.post(
        `/turbine-issues/${+payload.issueId}/statuses`,
        body,
      );

      if (res.status === 200) {
        commit("turbine/resetAllTurbineData", null, { root: true });
        if (!payload.bulk) {
          commit("site/clearSiteData", null, { root: true });
        }
        commit("issues/setClearIssues", null, {
          root: true,
        });
        commit("setUpdateIssueStatusResponse", res.data);
        commit("home/reset", null, { root: true });
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "updateIssueStatus",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "updateIssueStatus", error },
        { root: true },
      );
    }

    commit("setLoading", {
      name: "updateIssueStatus",
      value: false,
    });
  },
  async getLossesGains({ commit, dispatch }, { issueId, returnData, plot }) {
    try {
      commit("setLoading", {
        name: "getLossesGains",
        value: true,
      });

      commit("setLossesGains", null);
      const params = {
        start_date: null,
        end_date: null,
        return_data: returnData,
        plot,
      };
      const res = await axios.get(`/turbine-issues/${issueId}/losses-gains`, {
        params,
      });

      if (res.status === 200) {
        // Process the details markdown into an html string
        if (res.data.loss_model_details) {
          const response = processMarkdown(res.data.loss_model_details);
          // Replace the first paragraph tag to avoid block code behavior
          let html = response.replace(/^<p>{1}/g, "");
          commit("setLossModelDetailsHTML", html);
        }
        commit("setLossesGains", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getLossesGains",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getLossesGains", error },
        { root: true },
      );
    }
    commit("setLoading", {
      name: "getLossesGains",
      value: false,
    });
  },
  updateRecommendationSelection({ commit }, payload) {
    commit("setRecommendationSelection", payload);
  },
  clickedCancel({ commit }, payload) {
    commit("setCancel", payload);
  },
  async getSuggestedRecommendation({ commit, dispatch }, { issueId }) {
    try {
      commit("setLoading", {
        name: "getSuggestedRecommendation",
        value: true,
      });
      commit("setSuggestedRecommendation", null);
      const res = await axios.get(`/turbine-issues/${issueId}/recommendation`);
      if (res.status === 200) {
        commit("setSuggestedRecommendation", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getSuggestedRecommendation",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSuggestedRecommendation", error },
        { root: true },
      );
    }
    commit("setLoading", {
      name: "getSuggestedRecommendation",
      value: false,
    });
  },
  async editTurbineIssueDetails({ commit, dispatch, rootState }, payload) {
    try {
      commit("setLoading", {
        name: "editTurbineIssueDetails",
        value: true,
      });

      const params = {
        details: payload.details,
      };
      const id = payload.id;

      const res = await axios.put(`/turbine-issues/${id}/details`, null, {
        params,
      });

      if (res.status === 200) {
        commit("setIssueData", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "editTurbineIssueDetails",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "editTurbineIssueDetails", error },
        { root: true },
      );
    }

    commit("setLoading", {
      name: "editTurbineIssueDetails",
      value: false,
    });
  },
  async editIssueEvidence({ commit, dispatch }, payload) {
    try {
      commit("setLoading", {
        name: "editIssueEvidence",
        value: true,
      });
      const body = {
        title: payload.title,
        description: payload.description,
        internal: payload.internal,
      };
      const id = payload.id;

      const res = await axios.put(`/turbine-issue-evidence/${id}`, body);

      if (res.status === 200) {
        const response = {
          evidence: res.data,
          evidenceIndex: payload.evidenceIndex,
        };
        commit("evidence/setEditedEvidenceItem", response, {
          root: true,
        });
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "editIssueEvidence",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "editIssueEvidence", error },
        { root: true },
      );
    }

    commit("setLoading", {
      name: "editIssueEvidence",
      value: false,
    });
  },
  clearEditEvidenceResponse({ commit }) {
    commit("setEditEvidenceResponse", {});
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setIssueTurbineId(state, data) {
    localStorage.setItem("issueTurbineId", data);
    state.issueTurbineId = data;
  },
  setIssueData(state, data) {
    state.issueData = data;
  },
  setDefinition(state, data) {
    state.definition = data;
  },
  setIssueStatusData(state, data) {
    state.issueStatuses = [];
    state.issueStatuses = data.possible_statuses || [];
    state.statusHistory = data.status_history || [];

    if (data.status_history && data.status_history.length > 0) {
      const statusIndex = data.status_history.length - 1;
      const latestStatus = data.status_history[statusIndex];
      state.latestStatus = latestStatus;

      const status = data.possible_statuses.find(
        (item) => item.name_external === latestStatus.definition_name_external,
      );

      if (!status) {
        state.issueStatuses.unshift(latestStatus);
      }
    }
  },
  setLossesGains(state, data) {
    state.lossesGains = data;
  },
  setUpdateIssueStatusResponse(state, data) {
    state.updateIssueStatusResponse = data;
  },
  setTurbineModDefs(state, data) {
    state.turbineModDefs = data;
  },
  setRecommendationSelection(state, data) {
    state.recommendationSelection = data;
  },
  setCancel(state, data) {
    state.cancel = data;
  },
  setSuggestedRecommendation(state, data) {
    state.suggestedRecommendation = data;
  },
  setLossModelDetailsHTML(state, payload) {
    state.lossModelDetailsHTML = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
