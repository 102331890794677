import axios from "@/helpers/axiosAPI";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

// initial state
const state = () => ({
  loading: {
    getSwarmAlerts: false,
    getSwarmStatus: false,
    getSwarmInfo: false,
    getSwarmEdgeTs: false,
    getServerDeployments: false,
    getEdgeDeployments: false,
    postServerDeployment: false,
    postEdgeDeployment: false,
    getSwarmAvailability: false,
    getSwarmAvailabilityPlots: false,
  },
  swarmAlerts: [],
  swarmStatus: {},
  swarmInfo: {},
  swarmEdgeTs: [],
  serverDeployments: [],
  edgeDeployments: [],
  postServerResponse: {},
  postEdgeResponse: {},
  swarmAvailability: {},
  swarmAvailabilityPlots: {},
  isFromSwarm: false,
});
// actions
const actions = {
  async getSwarmAlerts({ commit, dispatch, rootState }, siteId) {
    try {
      commit("setLoading", { name: "getSwarmAlerts", value: true });
      commit("setSwarmAlerts", []);

      const res = await axios.get(`/sites/${siteId}/swarm/alerts`);

      if (res.status === 200) {
        commit("setSwarmAlerts", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getSwarmAlerts",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSwarmAlerts", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getSwarmAlerts", value: false });
  },
  async getSwarmStatus({ commit, dispatch, rootState }, siteId) {
    try {
      commit("setLoading", { name: "getSwarmStatus", value: true });
      commit("setSwarmStatus", {});

      const res = await axios.get(`/sites/${siteId}/swarm/status`);

      if (res.status === 200) {
        commit("setSwarmStatus", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getSwarmStatus",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSwarmStatus", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getSwarmStatus", value: false });
  },
  async getSwarmInfo({ commit, dispatch, rootState }, siteId) {
    try {
      commit("setLoading", { name: "getSwarmInfo", value: true });
      commit("setSwarmInfo", {});

      const res = await axios.get(`/sites/${siteId}/swarm`);

      if (res.status === 200) {
        commit("setSwarmInfo", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getSwarmInfo",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSwarmInfo", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getSwarmInfo", value: false });
  },
  async getServerDeployments({ commit, dispatch, rootState }, siteId) {
    try {
      commit("setLoading", { name: "getServerDeployments", value: true });
      commit("setServerDeployments", []);
      const params = {
        user_aws_sub: rootState.user.awsSub,
      };

      const res = await axios.get(`/sites/${siteId}/swarm/server-deployments`);

      if (res.status === 200) {
        commit("setServerDeployments", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getServerDeployments",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getServerDeployments", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getServerDeployments", value: false });
  },
  async getEdgeDeployments({ commit, dispatch, rootState }, siteId) {
    try {
      commit("setLoading", { name: "getEdgeDeployments", value: true });
      commit("setEdgeDeployments", []);

      const res = await axios.get(`/sites/${siteId}/swarm/edge-deployments`);

      if (res.status === 200) {
        commit("setEdgeDeployments", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getEdgeDeployments",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getEdgeDeployments", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getEdgeDeployments", value: false });
  },
  async postServerDeployment({ commit, dispatch, rootState }, payload) {
    try {
      commit("setLoading", { name: "postServerDeployment", value: true });
      const site_id = payload.site_id;
      const body = {
        version: payload.version,
        expect_perf_change: payload.expect_perf_change,
        values: JSON.parse(payload.values),
        notes: payload.notes,
      };

      const res = await axios.post(
        `/sites/${site_id}/swarm/server-deployments`,
        body,
      );

      if (res.status === 200) {
        res.data.success = true;
        commit("setPostServerResponse", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "postServerDeployment",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "postServerDeployment", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "postServerDeployment", value: false });
  },
  async postEdgeDeployment({ commit, dispatch, rootState }, payload) {
    try {
      commit("setLoading", { name: "postEdgeDeployment", value: true });
      const siteId = payload.site_id;
      const body = {
        turbine_id: payload.turbine_id,
        end_ts: payload.ts,
        version: payload.version,
        config: JSON.parse(payload.config),
        notes: payload.notes,
      };

      const res = await axios.post(
        `/sites/${siteId}/swarm/edge-deployments`,
        body,
      );

      if (res.status === 200) {
        res.data.success = true;
        commit("setPostEdgeResponse", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "postEdgeDeployment",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "postEdgeDeployment", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "postEdgeDeployment", value: false });
  },
  async getSwarmEdgeTs({ commit, dispatch, rootState }, payload) {
    try {
      commit("setLoading", { name: "getSwarmEdgeTs", value: true });
      commit("setSwarmEdgeTs", []);
      const params = {
        start_date: payload.startDate,
        end_date: payload.endDate,
        plot: 1,
      };
      const res = await axios.get(
        `/sites/${payload.siteId}/swarm/edge-state-ts`,
        {
          params,
        },
      );
      if (res.status === 200) {
        commit("setSwarmEdgeTs", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getSwarmEdgeTs",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSwarmEdgeTs", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getSwarmEdgeTs", value: false });
  },
  async getSwarmAvailability({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getSwarmAvailability", value: true });
      commit("setSwarmAvailability", {});
      let startDate = new Date();
      const params = {
        end_date: new Date(),
      };
      startDate.setDate(params.end_date.getDate() - 7);
      params.start_date = dayjs(startDate).format("YYYY-MM-DD");
      params.end_date = dayjs(params.end_date).format("YYYY-MM-DD");
      const promise1 = axios.get(
        `/sites/${payload.siteId}/swarm/availability`,
        {
          params,
        },
      );
      let respData = {};
      const promise2 = axios.get(
        `/sites/${payload.siteId}/swarm/wake-steering-availability`,
        {
          params,
        },
      );
      await Promise.all([promise1, promise2]).then((values) => {
        const res1 = values[0];
        const res2 = values[1];
        if (res1.status === 200) {
          respData = res1.data;
        } else {
          dispatch(
            "error/raiseError",
            {
              name: "getSwarmAvailability",
              ...{
                code: res1.status,
                message: "Error API call",
                value: "unknown error",
              },
            },
            { root: true },
          );
        }
        if (res2.status === 200) {
          respData["wake_steering_pct"] = res2.data.overall_availability_pct;
        } else {
          dispatch(
            "error/raiseError",
            {
              name: "getSwarmAvailability",
              ...{
                code: res2.status,
                message: "Error API call",
                value: "unknown error",
              },
            },
            { root: true },
          );
        }
        commit("setSwarmAvailability", respData);
      });
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSwarmAvailability", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getSwarmAvailability", value: false });
  },

  async getSwarmAvailabilityPlots({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getSwarmAvailabilityPlots", value: true });
      commit("setSwarmAvailabilityPlots", {});
      const params = { plot_edges: true, plot_server: true };

      const siteId = payload.siteId;

      if (payload.startDate !== "Invalid Date") {
        params.start_date = dayjs(payload.startDate).format("YYYY-MM-DD");
      }
      if (payload.endDate !== "Invalid Date") {
        params.end_date = dayjs(payload.endDate).format("YYYY-MM-DD");
      }
      const res = await axios.get(`/sites/${siteId}/swarm/availability`, {
        params,
      });
      if (res.status === 200) {
        commit("setSwarmAvailabilityPlots", res.data.figures);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getSwarmAvailabilityPlots",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSwarmAvailabilityPlots", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getSwarmAvailabilityPlots", value: false });
  },

  updateIsFromSwarm({ commit }, isFromSwarm) {
    commit("setIsFromSwarm", isFromSwarm);
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setSwarmAlerts(state, payload) {
    state.swarmAlerts = payload;
  },
  setSwarmStatus(state, payload) {
    state.swarmStatus = payload;
  },
  setSwarmInfo(state, payload) {
    state.swarmInfo = payload;
  },
  setSwarmEdgeTs(state, payload) {
    state.swarmEdgeTs = payload;
  },
  setServerDeployments(state, payload) {
    state.serverDeployments = payload;
  },
  setPostServerResponse(state, payload) {
    state.postServerResponse = payload;
  },
  setEdgeDeployments(state, payload) {
    state.edgeDeployments = payload;
  },
  setPostEdgeResponse(state, payload) {
    state.postEdgeResponse = payload;
  },
  setSwarmAvailability(state, payload) {
    state.swarmAvailability = payload;
  },
  setSwarmAvailabilityPlots(state, payload) {
    state.swarmAvailabilityPlots = payload;
  },
  setIsFromSwarm(state, payload) {
    state.isFromSwarm = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
