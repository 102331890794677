import axios from "@/helpers/axiosAPI";

// initial state
const state = () => ({
  loading: {
    getOrgData: false,
    getSummary: false,
    getIssuesGroupedBySite: false,
    getIssuesGroupedByOEM: false,
    getPaginatedIssuesForIssuesTable: false,
    getChecks: false,
  },
  summary: {},
  orgData: {},
  issuesGroupedBySite: [],
  issuesGroupedByOEM: {},
  checks: [],
  paginatedIssuesForIssuesTable: [],
  issuesTotal: 0,
  abortControllerPaginatedIssues: new AbortController(),
});

// actions
const actions = {
  async getOrgData({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getOrgData", value: true });
      commit("setOrgData", {});
      const params = {
        get_issues_summary: payload.issuesSummary,
        recursive: 0,
      };

      const res = await axios.get(`/orgs/${payload.orgId}`, {
        params,
      });

      if (res.status === 200) {
        res.data.entityType = "org";
        commit("setOrgData", res.data);
      } else {
        commit("setOrgData", {});
        dispatch(
          "error/raiseError",
          {
            name: "getOrgData",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getOrgData", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getOrgData", value: false });
  },
  async getIssuesGroupedBySite({ commit, dispatch }, payload) {
    try {
      commit("setLoading", {
        name: "getIssuesGroupedBySite",
        value: true,
      });
      commit("setIssuesGroupedBySite", []);
      let orgId = null;
      if (typeof payload.orgId === "number") {
        orgId = payload.orgId;
      } else if (typeof payload.orgId === "string") {
        orgId = parseInt(payload.orgId);
      }
      const params = {
        get_losses_gains: false,
        group_by: "site",
        exclude_unconfirmed: true,
      };

      const res = await axios.get(`/orgs/${orgId}/turbine-issues`, {
        params,
      });

      if (res.status === 200) {
        commit("setIssuesGroupedBySite", res.data.results);
      } else {
        commit("setIssuesGroupedBySite", []);
        dispatch(
          "error/raiseError",
          {
            name: "getIssuesGroupedBySite",
            ...{
              code: res.status,
              message: res.data,
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getIssuesGroupedBySite", error },
        { root: true },
      );
    }
    commit("setLoading", {
      name: "getIssuesGroupedBySite",
      value: false,
    });
  },
  async getChecks({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getChecks", value: true });
      commit("setChecks", []);
      let orgId = null;
      if (typeof payload.orgId === "number") {
        orgId = payload.orgId;
      } else if (typeof payload.orgId === "string") {
        orgId = parseInt(payload.orgId);
      }
      const params = {
        group_by: "issue",
        get_losses_gains: true,
        exclude_unconfirmed: false,
      };

      const res = await axios.get(`/orgs/${orgId}/turbine-issues`, {
        params,
      });

      if (res.status === 200) {
        res.data.results[0].org_id = orgId;
        commit("setChecks", res.data.results);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getChecks",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getChecks", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getChecks", value: false });
  },
  async getPaginatedIssuesForIssuesTable(
    { commit, dispatch, rootState },
    payload,
  ) {
    try {
      // Cancel the previous request
      rootState.org.abortControllerPaginatedIssues.abort();
      // Create a new abort controller for the current request
      commit("setAbortControllerPaginatedIssues");
      commit("setLoading", {
        name: "getPaginatedIssuesForIssuesTable",
        value: true,
      });
      commit("setPaginatedIssues", []);
      let includeStatusString = "";
      const itemsPerPage = parseInt(payload.items_per_page);
      const page = parseInt(payload.page);
      const sortDesc = parseInt(payload.sort_desc);
      const params = {
        summarize: 0,
      };
      params.limit = itemsPerPage;
      params.offset = (page - 1) * itemsPerPage;
      params.sort_desc = sortDesc;
      params.group_by = null;
      if (payload.sort_by) {
        params.sort_by = payload.sort_by;
      }
      if ("include_statuses" in payload) {
        let statuses = [];
        if (payload.include_statuses.length > 0) {
          for (const item of payload.include_statuses) {
            if (item.includes("_")) {
              const splitItem = item.split("_");
              const status = `${splitItem[0]} ${splitItem[1]}`;
              statuses.push(status);
            } else {
              statuses.push(item);
            }
          }
          statuses.forEach((status) => {
            includeStatusString += `&include_status=${status}`;
          });
        } else {
          // Empty all vars and release loading if no statuses supplied
          commit("setPaginatedIssues", []);
          commit("setIssuesTotal", 0);
          commit("setLoading", {
            name: "getPaginatedIssuesForIssuesTable",
            value: false,
          });
          return;
        }
      }
      if (payload.search_for) {
        params.search_for = payload.search_for;
      }

      const res = await axios.get(
        `/orgs/${payload.orgId}/turbine-issues?${includeStatusString}`,
        {
          signal: rootState.org.abortControllerPaginatedIssues.signal,
          params,
        },
      );

      if (res.status === 200) {
        let data = res.data.results.map((item) => ({
          ...item,
          loss_upper_usd: item.aep_loss_pct < 0.05 ? 0 : item.loss_upper_usd,
        }));
        commit("setPaginatedIssues", data);
        commit("setIssuesTotal", res.data.total);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getPaginatedIssuesForIssuesTable",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      if (error.name === "AbortError") {
        commit("setLoading", {
          name: "getPaginatedIssuesForIssuesTable",
          value: false,
        });
        return;
      } else {
        dispatch(
          "error/raiseError",
          { name: "getPaginatedIssuesForIssuesTable", error },
          { root: true },
        );
      }
    }
    commit("setLoading", {
      name: "getPaginatedIssuesForIssuesTable",
      value: false,
    });
  },
  async getIssuesGroupedByOEM({ commit, dispatch }, orgId) {
    try {
      commit("setLoading", {
        name: "getIssuesGroupedByOEM",
        value: true,
      });
      commit("setIssuesGroupedByOEM", []);
      let params = {
        group_by: "manufacturer",
      };

      const res = await axios.get(`/orgs/${orgId}/turbine-issues`, {
        params,
      });

      if (res.status === 200) {
        res.data.org_id = orgId;
        commit("setIssuesGroupedByOEM", res.data);
      } else {
        commit("setIssuesGroupedByOEM", []);
        dispatch(
          "error/raiseError",
          {
            name: "getIssuesGroupedByOEM",
            ...{
              code: res.status,
              message: res.data,
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getIssuesGroupedByOEM", error },
        { root: true },
      );
    }
    commit("setLoading", {
      name: "getIssuesGroupedByOEM",
      value: false,
    });
  },
};

// mutations
const mutations = {
  setOrgData(state, payload) {
    state.orgData = payload;
  },
  setIssuesGroupedBySite(state, payload) {
    state.issuesGroupedBySite = payload;
  },
  setChecks(state, payload) {
    state.checks = payload;
  },
  setPaginatedIssues(state, data) {
    state.paginatedIssuesForIssuesTable = data;
  },
  setIssuesTotal(state, data) {
    state.issuesTotal = data;
  },
  setIssuesGroupedByOEM(state, data) {
    state.issuesGroupedByOEM = data;
  },
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setAbortControllerPaginatedIssues(state) {
    state.abortControllerPaginatedIssues = new AbortController();
  },
  reset(state) {
    state.summary = {};
    state.checks = [];
    state.orgData = [];
    state.issuesGroupedBySite = [];
    state.issuesGroupedByOEM = [];
    state.paginatedIssuesForIssuesTable = [];
    state.issuesTotal = 0;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
