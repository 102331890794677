import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import sites from "./modules/sites/dashboard";
import notifications from "./modules/notifications";
import documents from "./modules/documents";
import user from "./modules/user";
import admin from "./modules/admin";
import userList from "./modules/user-list";
import issueDetail from "./modules/issue-detail";
import comments from "./modules/comments";
import glossary from "./modules/glossary";
import swarm from "./modules/swarm";
import evidence from "./modules/evidence";
import orgs from "./modules/orgs";
import turbineModels from "./modules/turbine-models";
import elevation from "./modules/elevation";
import error from "./modules/error";
import home from "./modules/home";
import org from "./modules/org";
import site from "./modules/site";
import turbine from "./modules/turbine";
import issues from "./modules/issues";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    user,
    sites,
    issues,
    notifications,
    documents,
    admin,
    userList,
    issueDetail,
    comments,
    glossary,
    swarm,
    evidence,
    orgs,
    turbineModels,
    elevation,
    error,
    home,
    org,
    site,
    turbine,
  },
  strict: process.env.NODE_ENV !== "production",
});
