import axios from "@/helpers/axiosAPI";

// initial state
const state = () => ({
  loading: {
    getElevation: false,
  },
  elevation: null,
});

// actions
const actions = {
  async getElevation({ commit, dispatch, rootState }, params) {
    try {
      commit("setLoading", { name: "getElevation", value: true });
      commit("setElevation", []);

      const res = await axios.get(
        `/elevation?lat=${params.lat}&lon=${params.lon}`,
      );

      if (res.status === 200) {
        commit("setElevation", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getElevation",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getElevation", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getElevation", value: false });
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setElevation(state, data) {
    state.elevation = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
