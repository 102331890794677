export function setNewLookPreference(isNewLook) {
  localStorage.setItem("isNewLookEnabled", isNewLook);
}

export function getNewLookPreference() {
  return localStorage.getItem("isNewLookEnabled") === "true";
}

export const pageMappings = {
  HomePage: "GlobalDashboard",
  OrgDashboard: "NewOrgDashboard",
  SiteDashboard: "NewSiteDashboard",
  TurbineDashboard: "NewTurbineDashboard",
  IssueDetail: "NewIssueDetail",
};

export const reversePageMappings = Object.entries(pageMappings).reduce(
  (acc, [oldPage, newPage]) => {
    acc[newPage] = oldPage;
    return acc;
  },
  {},
);
