import axios from "@/helpers/axiosAPI";
import api from "../api";
import router from "@/router";

// initial state
const state = () => ({
  loading: {
    getIssues: false,
    getPortfolioIssues: false,
    getIssueStatusDefinitions: false,
    getPaginatedIssuesForIssuesTable: false,
  },
  portfolioIssues: [],
  issues: [],
  paginatedIssues: [],
  issuesTotal: 0,
  allIssuesByEntityId: [],
  allSiteIssuesByDefId: [],
  oemIssues: [],
  orgIssues: [],
  siteIssues: [],
  turbineIssues: [],
  issuesOpen: [],
  issuesFixed: [],
  issuesResolved: [],
  issuesRequireAttention: [],
  confirmedIssues: [],
  issueStatusDefinitions: [],
  issueByDefId: {},
  issuesGroupedBySiteForHome: [],
  issuesGroupedByOrgForHome: [],
  issuesForOrgsTable: [],
  summary: null,
  abortController: new AbortController(),
});

// actions
const actions = {
  async getPortfolioIssues({ commit, dispatch }) {
    try {
      commit("setLoading", { name: "getPortfolioIssues", value: true });
      commit("setPortfolioIssues", []);

      const data = await api.getPortfolioIssues();
      commit("setPortfolioIssues", data.issues);
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getPortfolioIssues", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getPortfolioIssues", value: false });
  },
  async getIssues(
    { commit, dispatch },
    {
      orgId,
      siteId,
      turbineId,
      issueDefId,
      excludeUnconfirmed = false,
      entityType,
      entityId,
      groupBy,
      summarize,
      componentType,
      ...payload
    },
  ) {
    try {
      commit("setLoading", { name: "getIssues", value: true });
      commit("setIssues", []);
      var entity_type = null;
      let entity_id = null;
      let group_by = groupBy ? groupBy : null;
      let url = "/turbine-issues";

      if (siteId) {
        entity_type = "site";
        entity_id = siteId;
        url = `/sites/${siteId}/turbine-issues`;
      } else if (orgId) {
        entity_type = "org";
        entity_id = orgId;
        url = `/orgs/${orgId}/turbine-issues`;
      } else if (turbineId) {
        entity_type = "turbine";
        entity_id = turbineId;
        url = `/turbines/${turbineId}/issues`;
      } else if (entityType === "turbine_issue") {
        entity_type = entityType;
        entity_id = entityId;
      } else if (issueDefId) {
        entity_type = entityType;
        entity_id = issueDefId;
      }
      // Call this api with turbine id and use all issues that are not unconfirmed
      // from the response for dropdown in issue detail widget
      const params = {
        group_by: group_by,
        get_losses_gains: true,
        exclude_unconfirmed: excludeUnconfirmed,
        summarize: summarize,
        component_type: componentType ? componentType : null,
      };

      if (payload.limit) {
        params.limit = payload.limit;
        params.offset = payload.offset;
      }

      let res = null;
      if (entity_type === "turbine_issue") {
        if (entityId) {
          url += "?";
          entityId.forEach((id) => {
            url += `turbine_issue_id=${id}&`;
          });
        }
      }

      res = await axios.get(url, { params });
      if (summarize !== 1) {
        var issuesOpen = res.data.results.filter((issue) => issue.is_open);
        var issuesFixed = res.data.results.filter((issue) => issue.is_fixed);
        var issuesResolved = res.data.results.filter(
          (issue) => issue.is_resolved,
        );
        var issuesRequireAttention = res.data.results.filter(
          (issue) => issue.requires_external_input,
        );
      }

      if (res.status === 200) {
        if (summarize === 1) {
          commit("setSummary", res.data);
        } else {
          let data = res.data.results.map((item) => ({
            ...item,
            loss_upper_usd: item.aep_loss_pct < 0.05 ? 0 : item.loss_upper_usd,
          }));
          data.entityType = entity_type;
          data.group_by = group_by;
          commit("setIssues", data);
          commit("setIssuesOpen", issuesOpen);
          commit("setIssuesFixed", issuesFixed);
          commit("setIssuesResolved", issuesResolved);
          commit("setIssuesRequireAttention", issuesRequireAttention);
          commit("setConfirmedIssues", data);
        }
      } else {
        const data = { entity_type, group_by };
        commit("setIssues", data);

        dispatch(
          "error/raiseError",
          {
            name: "getIssues",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      const data = { entity_type };
      commit("setIssues", data);
      dispatch(
        "error/raiseError",
        { name: "getIssues", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getIssues", value: false });
  },
  async getPaginatedIssuesForIssuesTable(
    { commit, dispatch, rootState },
    { orgId, siteId, turbineId, ...payload },
  ) {
    try {
      // Cancel the previous request
      rootState.issues.abortController.abort();
      // Create a new abort controller for the current request
      commit("setAbortController");
      commit("setLoading", {
        name: "getPaginatedIssuesForIssuesTable",
        value: true,
      });
      commit("setPaginatedIssues", []);
      let includeStatusString = "";
      const itemsPerPage = parseInt(payload.items_per_page);
      const page = parseInt(payload.page);
      const sortDesc = parseInt(payload.sort_desc);
      let entity_type = null;
      let entity_id = null;
      let url = "/turbine-issues";
      if (siteId) {
        entity_type = "site";
        entity_id = siteId;
        url = `/sites/${siteId}/turbine-issues`;
      } else if (orgId) {
        entity_type = "org";
        entity_id = orgId;
        url = `/orgs/${orgId}/turbine-issues`;
      } else if (turbineId) {
        entity_type = "turbine";
        entity_id = turbineId;
        url = `/turbines/${turbineId}/issues`;
      }
      const params = {
        summarize: 0,
      };
      params.limit = itemsPerPage;
      params.offset = (page - 1) * itemsPerPage;
      params.sort_desc = sortDesc;
      params.group_by = null;
      if (payload.sort_by) {
        params.sort_by = payload.sort_by;
      }

      if ("include_statuses" in payload) {
        let statuses = [];
        if (payload.include_statuses.length > 0) {
          for (const item of payload.include_statuses) {
            if (item.includes("_")) {
              const splitItem = item.split("_");
              const status = `${splitItem[0]} ${splitItem[1]}`;
              statuses.push(status);
            } else {
              statuses.push(item);
            }
          }
          statuses.forEach((status) => {
            includeStatusString += `&include_status=${status}`;
          });
        } else {
          commit("setPaginatedIssues", []);
          commit("setIssuesTotal", 0);
          commit("setLoading", {
            name: "getPaginatedIssuesForIssuesTable",
            value: false,
          });
          return;
        }
      }

      if (payload.search_for) {
        params.search_for = payload.search_for;
      }

      const res = await axios.get(`${url}?${includeStatusString}`, {
        signal: rootState.issues.abortController.signal,
        params,
      });

      if (res.status === 200) {
        let data = res.data.results.map((item) => ({
          ...item,
          loss_upper_usd: item.aep_loss_pct < 0.05 ? 0 : item.loss_upper_usd,
        }));
        data.entityType = entity_type;
        data.group_by = params.group_by;
        commit("setPaginatedIssues", data);
        commit("setIssuesTotal", res.data.total);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getPaginatedIssuesForIssuesTable",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      if (error.name === "AbortError") {
        commit("setLoading", {
          name: "getPaginatedIssuesForIssuesTable",
          value: false,
        });
        return;
      } else {
        dispatch(
          "error/raiseError",
          { name: "getPaginatedIssuesForIssuesTable", error },
          { root: true },
        );
      }
    }
    commit("setLoading", {
      name: "getPaginatedIssuesForIssuesTable",
      value: false,
    });
  },
  async getIssueStatusDefinitions({ commit, dispatch, rootState }) {
    try {
      commit("setLoading", { name: "getIssueStatusDefinitions", value: true });
      commit("setIssueStatusDefinitions", []);

      let statusArray = [];

      const res = await axios.get("/turbine-issue-status-defs");

      if (res.status === 200) {
        if (rootState.user.userData.is_internal) {
          statusArray = res.data;
        } else {
          for (const status of res.data) {
            if (status.visible_external) {
              statusArray.push(status);
            }
          }
        }
        commit("setIssueStatusDefinitions", statusArray);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "setIssueStatusDefinitions",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "setIssueStatusDefinitions", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getIssueStatusDefinitions", value: false });
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setIssues(state, data) {
    if (data.group_by === "manufacturer") {
      state.oemIssues = data;
    }
    if (data.entityType === "org" && data.group_by !== "org") {
      state.orgIssues = data;
    }
    if (data.entityType === "site" && data.group_by !== "site") {
      state.siteIssues = data;
    }
    if (data.entityType === "turbine") {
      state.turbineIssues = data;
    }
    if (data.entityType === "turbine_issue") {
      state.allIssuesByEntityId = data;
    }
    if (data.entityType === "turbine_issue_type" && data.group_by === "site") {
      state.allSiteIssuesByDefId = data;
    }
    if (
      router.currentRoute.params.issueDefId != null &&
      data.group_by !== "site"
    ) {
      state.issueByDefId = data?.filter(
        (issue) =>
          issue.turbine_issue_def_id == router.currentRoute.params.issueDefId,
      )[0];
    }
    state.issues = data;
  },
  setIssuesGroupedBySiteForHome(state, data) {
    state.issuesGroupedBySiteForHome = data;
  },
  setPaginatedIssues(state, data) {
    state.paginatedIssues = data;
  },
  // Set total count of issues for server-side pagination
  setIssuesTotal(state, data) {
    state.issuesTotal = data;
  },
  setConfirmedIssues(state, data) {
    const allConfirmedIssues = data.filter(
      (issue) =>
        issue.is_confirmed && !issue.is_dismissed && !issue.is_invalidated,
    );
    state.confirmedIssues = allConfirmedIssues;
  },
  setSummary(state, data) {
    state.summary = data;
  },
  setClearIssues(state) {
    state.issues = [];
    state.turbineIssues = [];
    state.siteIssues = [];
    state.orgIssues = [];
    state.oemIssues = [];
  },
  setIssuesOpen(state, data) {
    state.issuesOpen = data;
  },
  setIssuesUnconfirmed(state, data) {
    state.issuesUnconfirmed = data;
  },
  setIssuesFixed(state, data) {
    state.issuesFixed = data;
  },
  setIssuesResolved(state, data) {
    state.issuesResolved = data;
  },
  setIssuesRequireAttention(state, data) {
    state.issuesRequireAttention = data;
  },
  setPortfolioIssues(state, data) {
    state.portfolioIssues = data;
  },
  setIssueStatusDefinitions(state, data) {
    state.issueStatusDefinitions = data;
  },
  setAbortController(state) {
    state.abortController = new AbortController();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
