import axios from "@/helpers/axiosAPI";

// initial state
const state = () => ({
  loading: {
    getDocumentData: false,
    postDocumentData: false,
    getDocumentDefinitions: false,
  },
  documentData: null,
  postDataResponse: {},
  documentDefinitions: [],
  successDialog: false,
  timer: null,
  spreadsheetLoading: false,
});

// actions
const actions = {
  async getDocumentData({ commit, dispatch }, { siteId }) {
    commit("setDocumentData", []);
    try {
      commit("setLoading", { name: "getDocumentData", value: true });

      const res = await axios.get(`/sites/${siteId}/documents`);

      if (res.status === 200) {
        commit("setDocumentData", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getDocumentData",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getDocumentData", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getDocumentData", value: false });
  },
  async postDocumentData(
    { commit, dispatch, rootState },
    { siteId, docDefId, file },
  ) {
    try {
      commit("setLoading", { name: "postDocumentData", value: true });

      let formData = new FormData();
      formData.append("file", file);
      const params = {
        user_aws_sub: rootState.user.awsSub,
        site_id: siteId,
        document_def_id: docDefId,
      };

      const res = await axios.post(`/sites/${siteId}/documents`, formData, {
        params,
      });

      if (res.status === 200) {
        commit("setPostDataResponse", res.data);
        commit("setSuccessDialog", true);
        this.timer = setTimeout(() => {
          commit("setSuccessDialog", false);
        }, 2500);
      } else {
        commit("setSuccessDialog", false);
        dispatch(
          "error/raiseError",
          {
            name: "postDocumentData",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      commit("setSuccessDialog", false);
      dispatch(
        "error/raiseError",
        { name: "postDocumentData", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "postDocumentData", value: false });
  },
  async getDocumentDefinitions({ commit, dispatch }) {
    try {
      const res = await axios.get("/document-defs", {});

      if (res.status === 200) {
        commit("setDocumentDefinitions", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getDocumentDefinitions",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getDocumentDefinitions", error },
        { root: true },
      );
    }
  },
  updateSpreadsheetLoading({ commit }, value) {
    commit("setSpreadsheetLoading", value);
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setSpreadsheetLoading(state, data) {
    state.spreadsheetLoading = data;
  },
  setDocumentData(state, data) {
    state.documentData = data;
  },
  setPostDataResponse(state, data) {
    if (Object.keys(state.postDataResponse)?.length > 0) {
      state.postDataResponse = {};
    }
    state.postDataResponse = data;
  },
  setDocumentDefinitions(state, data) {
    if (state.documentDefinitions?.length > 0) {
      state.documentDefinitions = [];
    }
    state.documentDefinitions = data;
  },
  setSuccessDialog(state, data) {
    state.successDialog = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
