import { removeDuplicates } from "@/helpers/functions";
import router from "@/router";
import store from "@/store";

// initial state
const state = () => ({
  errors: [],
});

// actions
const actions = {
  raiseError({ commit }, payload) {
    const constructError = (payload) => {
      const value = `Something went wrong. If this issue persists, 
      please contact us.`;
      if (payload instanceof Error) {
        return {
          name: payload.name,
          code: payload.code,
          message: payload.message,
          value,
        };
      } else if (payload instanceof Object) {
        if (payload.error) {
          return {
            name: payload.error.name,
            code: payload.error.code,
            message: payload.error.message,
            value,
          };
        }
      } else if (payload.message) {
        return {
          name: payload.name,
          code: payload.code,
          message: payload.message,
          value,
        };
      } else if (payload.err) {
        return {
          name: payload.err.name,
          code: payload.err.code,
          message: payload.err.message,
          value,
        };
      }
    };
    const error = constructError(payload);
    // Display friendly message for 5xx error codes
    if (error?.code?.toString()[0] == 5) {
      error.value = "Something went wrong. Please try again.";
    }
    if (error.name === "login") {
      error.value = "Invalid username or password.";
    } else if (["forgotPassword", "confirmPasswordCode"].includes(error.name)) {
      error.value = payload?.error?.response.data.detail;
    }
    if (
      error.message !== "canceled" &&
      error.name !== "CanceledError" &&
      error.value.toLowerCase() !== "refresh token has expired" &&
      error.value.toLowerCase() !== "the incoming token has expired" &&
      error.value.toLowerCase() !== "unknown error"
    ) {
      commit("raiseError", error);
    }

    if (error?.code === 404) {
      router.push("/404");
    } else if (error?.code === 401) {
      if (error.name === "login") {
        return;
      } else {
        store.dispatch("user/logout");
        router.push({ name: "Login" });
      }
    }
  },
  dismissError({ commit }, index) {
    commit("dismissError", index);
  },
  dismissErrorsByName({ commit }, payload) {
    commit("dismissErrorsByName", payload);
  },
  clearAllErrors({ commit }) {
    commit("clearErrors");
  },
};

// mutations
const mutations = {
  raiseError(state, error) {
    state.errors.push(error);
    state.errors = removeDuplicates(state.errors);
  },
  dismissError(state, index) {
    state.errors.splice(index, 1);
  },
  dismissErrorsByName(state, payload) {
    const filteredErrors = state.errors.filter((obj) => {
      return obj.name !== payload.name;
    });

    state.errors = filteredErrors;
  },
  clearErrors(state) {
    state.errors = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
