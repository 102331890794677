import axios from "@/helpers/axiosAPI";
import Axios from "axios";
import { setUploadType, getFile } from "@/helpers/functions";
import router from "@/router";
import L from "leaflet";
import { COLORS } from "@/helpers/colors";
import {
  roundToString,
  roundAepPct,
  formatTimestamp,
} from "@/helpers/functions";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

// initial state
const state = () => ({
  loading: {
    getSite: false,
    getTurbinesBySiteId: false,
    getChecks: false,
    getPaginatedIssuesForIssuesTable: false,
    getDocumentData: false,
    getDocumentDefinitions: false,
    postDocumentData: false,
    getSiteRawdata: false,
    postSiteRawdata: false,
    editPowerPrice: false,
    getYawMisalignmentAnalytics: false,
    getMinPitchAnalytics: false,
    getWindRoseAnalytics: false,
    getModWindowSuitabilityAnalytics: false,
    getCheckDetails: false,
    getDataStatus: false,
    getSiteEventCounts: false,
    getSiteEventDurations: false,
  },
  siteData: {},
  siteSummary: {},
  subscriptionName: null,
  turbinesData: [],
  turbineIds: [],
  checks: [],
  paginatedIssuesForIssuesTable: [],
  paginatedIssuesTotal: 0,
  issuesForNewIssueDetail: [],
  documentData: null,
  postDataResponse: {},
  documentDefinitions: [],
  successDialog: false,
  timer: null,
  spreadsheetLoading: false,
  siteRawdata: [],
  isUploading: false,
  editPowerPriceResponse: {},
  yawMisalignmentAnalytics: {},
  minPitchAnalytics: {},
  windRoseAnalytics: {},
  modWindowSuitabilityAnalytics: {},
  singleCheckDetails: {},
  statusCards: [],
  abortControllerPaginatedIssues: new AbortController(),
  rawdataUpdated: false,
  uploadProgress: {},
  siteEventCounts: {},
  siteEventDurations: {},
});

const actions = {
  // Top dashboard cards
  async getSite({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getSite", value: true });
      commit("setSite", {});
      let siteId = payload.siteId;
      const params = {
        get_issues_summary: payload.issuesSummary,
        for_admin: 0,
      };
      if (typeof siteId === "string") {
        siteId = parseInt(siteId);
      }

      const res = await axios.get(`/sites/${siteId}`, {
        params,
      });

      if (res.status === 200) {
        commit("setSite", res.data);
      } else {
        commit("setSite", {});
        dispatch(
          "error/raiseError",
          {
            name: "getSite",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
      commit("setLoading", { name: "getSite", value: false });
    } catch (error) {
      commit("setLoading", { name: "getSite", value: false });
      dispatch("error/raiseError", { name: "getSite", error }, { root: true });
    }

    commit("setLoading", { name: "getSite", value: false });
  },
  // Data for top dashboard, map and turbine table
  async getTurbinesBySiteId({ commit, dispatch }, siteId) {
    try {
      commit("setLoading", { name: "getTurbinesBySiteId", value: true });
      commit("setTurbines", []);
      const params = {
        group_by: "turbine",
        exclude_unconfirmed: false,
      };
      const res = await axios.get(`/sites/${siteId}/turbine-issues`, {
        params,
      });

      if (res.status === 200) {
        commit("setTurbines", res.data.results);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getTurbinesBySiteId",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getTurbinesBySiteId", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getTurbinesBySiteId", value: false });
  },
  // Checks table data
  async getChecks({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getChecks", value: true });
      commit("setChecks", []);
      let site_id = null;
      if (typeof payload.siteId === "number") {
        site_id = payload.siteId;
      } else if (typeof payload.siteId === "string") {
        site_id = parseInt(payload.siteId);
      }
      const params = {
        group_by: "issue",
        get_losses_gains: true,
        exclude_unconfirmed: false,
      };
      if (payload.component_type) {
        let componentName = payload.component_type;
        if (componentName.includes("rotor-shaft")) {
          componentName = "main_bearing";
        } else if (componentName.charAt(componentName.length - 1) === "s") {
          componentName = componentName.slice(0, -1);
        }
        if (componentName.includes(" ")) {
          componentName = componentName.replace(" ", "_");
        }
        params.component_type = componentName;
      }

      const res = await axios.get(`/sites/${site_id}/turbine-issues`, {
        params,
      });

      if (res.status === 200) {
        res.data.results[0].site_id = site_id;
        commit("setChecks", res.data.results);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getChecks",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getChecks", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getChecks", value: false });
  },
  async getPaginatedIssuesForIssuesTable(
    { commit, dispatch, rootState },
    payload,
  ) {
    try {
      // Cancel the previous request
      rootState.site.abortControllerPaginatedIssues.abort();
      // Create a new abort controller for the current request
      commit("setAbortControllerPaginatedIssues");
      commit("setLoading", {
        name: "getPaginatedIssuesForIssuesTable",
        value: true,
      });
      commit("setPaginatedIssues", []);
      commit("setPaginatedIssuesTotal", 0);
      commit("setIssuesForNewIssueDetail", []);
      let includeStatusString = "";
      const itemsPerPage = parseInt(payload.items_per_page);
      const page = parseInt(payload.page);
      const sortDesc = parseInt(payload.sort_desc);
      const params = {
        summarize: 0,
      };
      params.limit = itemsPerPage;
      params.offset = (page - 1) * itemsPerPage;
      params.sort_desc = sortDesc;
      params.group_by = null;
      if (payload.sort_by) {
        params.sort_by = payload.sort_by;
      }
      if ("include_statuses" in payload) {
        let statuses = [];
        if (payload.include_statuses.length > 0) {
          for (const item of payload.include_statuses) {
            if (item.includes("_")) {
              const splitItem = item.split("_");
              const status = `${splitItem[0]} ${splitItem[1]}`;
              statuses.push(status);
            } else {
              statuses.push(item);
            }
          }
          statuses.forEach((status) => {
            includeStatusString += `&include_status=${status}`;
          });
        } else {
          // Empty all vars and release loading if no statuses supplied
          commit("setPaginatedIssues", []);
          commit("setPaginatedIssuesTotal", 0);
          commit("setLoading", {
            name: "getPaginatedIssuesForIssuesTable",
            value: false,
          });
          return;
        }
      }
      if (payload.search_for) {
        params.search_for = payload.search_for;
      }

      let url = `/sites/${payload.siteId}/turbine-issues`;
      let res = null;

      // Call for all issues of a particular site if on the new issue detail page for the next issue tab
      if ("component" in payload && payload.component === "new_issue_detail") {
        res = await axios.get(url, {
          signal: rootState.site.abortControllerPaginatedIssues.signal,
        });
      } else {
        // Paginated issues call for the issues table
        url = `${url}?${includeStatusString}`;
        res = await axios.get(url, {
          signal: rootState.site.abortControllerPaginatedIssues.signal,
          params,
        });
      }

      if (res.status === 200) {
        let data = res.data.results.map((item) => ({
          ...item,
          loss_upper_usd: item.aep_loss_pct < 0.05 ? 0 : item.loss_upper_usd,
        }));
        if (
          "component" in payload &&
          payload.component === "new_issue_detail"
        ) {
          commit("setIssuesForNewIssueDetail", data);
        } else {
          commit("setPaginatedIssues", data);
          commit("setPaginatedIssuesTotal", res.data.total);
        }
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getPaginatedIssuesForIssuesTable",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      if (error.name === "AbortError") {
        commit("setLoading", {
          name: "getPaginatedIssuesForIssuesTable",
          value: false,
        });
        return;
      } else {
        dispatch(
          "error/raiseError",
          { name: "getPaginatedIssuesForIssuesTable", error },
          { root: true },
        );
      }
    }
    commit("setLoading", {
      name: "getPaginatedIssuesForIssuesTable",
      value: false,
    });
  },
  // Document APIs for display and upload
  async getDocumentData({ commit, dispatch }, siteId) {
    try {
      commit("setLoading", { name: "getDocumentData", value: true });
      commit("setDocumentData", []);

      const res = await axios.get(`/sites/${siteId}/documents`);

      if (res.status === 200) {
        commit("setDocumentData", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getDocumentData",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getDocumentData", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getDocumentData", value: false });
  },
  async postDocumentData({ commit, dispatch }, { siteId, docDefId, file }) {
    try {
      commit("setLoading", { name: "postDocumentData", value: true });
      commit("setPostDataResponse", {});
      let formData = new FormData();
      formData.append("file", file);
      const params = {
        document_def_id: docDefId,
      };

      const res = await axios.post(`/sites/${siteId}/documents`, formData, {
        params,
      });

      if (res.status === 200) {
        commit("setPostDataResponse", res.data);
        commit("setSuccessDialog", true);
        this.timer = setTimeout(() => {
          commit("setSuccessDialog", false);
        }, 2500);
      } else {
        commit("setSuccessDialog", false);
        dispatch(
          "error/raiseError",
          {
            name: "postDocumentData",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      commit("setSuccessDialog", false);
      dispatch(
        "error/raiseError",
        { name: "postDocumentData", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "postDocumentData", value: false });
  },
  async getDocumentDefinitions({ commit, dispatch }) {
    try {
      commit("setDocumentDefinitions", []);

      const res = await axios.get("/document-defs", {});

      if (res.status === 200) {
        commit("setDocumentDefinitions", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getDocumentDefinitions",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getDocumentDefinitions", error },
        { root: true },
      );
    }
  },
  updateSpreadsheetLoading({ commit }, value) {
    commit("setSpreadsheetLoading", value);
  },
  // Site data APIS to get upload data for table and upload data
  async getSiteRawdata({ commit, dispatch, rootState }, payload) {
    const { siteId, uploaderType } = payload;
    try {
      commit("setLoading", { name: "getSiteRawdata", value: true });
      commit("setSiteRawdata", []);
      const params = {
        user_aws_sub: rootState.user.awsSub,
        uploader_type: uploaderType,
      };

      const res = await axios.get(`/sites/${siteId}/data/raw`, {
        params,
      });

      if (res.status === 200) {
        commit("setSiteRawdata", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getSiteRawdata",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSiteRawdata", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getSiteRawdata", value: false });
  },
  async postSiteRawdata({ commit, dispatch }, payload) {
    commit("setLoading", { name: "postSiteRawdata", value: true });
    const siteId = payload.site_id;
    const s3Uploads = [];
    let files = [];
    let parts = [];
    if (payload.file.length > 0) {
      for (const item of payload.file) {
        let file = item;
        let fileName = "";
        let fileSize = item.size;
        if (item.isFile) {
          file = await getFile(item);
          fileName = item.fullPath.replace("/", "");
        } else if (item.webkitRelativePath) {
          fileName = item.webkitRelativePath;
        } else {
          fileName = item.name;
        }
        files.push(file);
        const body = {
          name: fileName,
          kind: payload.kind,
          file_size: fileSize,
        };
        // Post to send initial data and receive s3 pre-signed url
        const s3FileResponse = await axios.post(
          `/sites/${siteId}/data/raw`,
          body,
        );
        if (s3FileResponse.status === 200) {
          // Update the current list of uploads in the table
          s3FileResponse.data.upload.uploading = true;
          s3Uploads.push(s3FileResponse.data);
          commit("addNewSiteRawdata", s3FileResponse.data);
        } else {
          dispatch(
            "error/raiseError",
            {
              name: "postSiteRawdata",
              ...{
                code: s3FileResponse.status,
                message: "Error attachment API call",
                value: "unknown error",
              },
            },
            { root: true },
          );
        }
      }
      if (s3Uploads.length > 0) {
        const uploadProgressObj = {};
        s3Uploads.forEach((item) => {
          const uploadId = item.upload.id;
          const urls = item.urls;
          const urlsObj = {};

          Object.keys(urls).forEach((key) => {
            urlsObj[urls[key]] = false;
          });

          uploadProgressObj[uploadId] = urlsObj;
        });
        commit("setUploadProgress", uploadProgressObj);
        try {
          for (const [i, uploadId] of Object.keys(
            uploadProgressObj,
          ).entries()) {
            // Create and upload parts
            parts = await dispatch("uploadParts", {
              file: files[i],
              urls: Object.keys(uploadProgressObj[uploadId]),
              uploadId: uploadId,
            });
            // Confirm that the upload has completed and update status
            const res = await axios({
              method: "put",
              url: `sites/${siteId}/data/raw/user/${s3Uploads[i].upload.id}`,
              data: JSON.stringify({
                upload_id: s3Uploads[i].post.UploadId,
                parts: parts,
              }),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            });
            if (res.status === 200) {
              // Set a loading property for each new upload
              res.data.uploading = false;
              commit("setRawdataUpdated", true);
              commit("updateRawdata", res.data);
            } else {
              dispatch(
                "error/raiseError",
                {
                  name: "postSiteRawdata",
                  ...{
                    code: res.status,
                    message: "Error API call",
                    value: "unknown error",
                  },
                },
                { root: true },
              );
            }
          }
        } catch (error) {
          const params = {
            new_status: "Upload failed",
          };
          const res = await axios({
            method: "patch",
            url: `sites/${siteId}/data/raw/user/${error.uploadId}/status`,
            params: params,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
          res.data.upload.uploading = false;
          commit("setRawdataUpdated", true);
          commit("updateRawdata", res.data.upload);
        }
      }
    }
  },
  async uploadParts({ commit }, payload) {
    const FILE_CHUNK_SIZE = 20_000_000;
    const axios = Axios.create();
    const MAX_RETRIES = 3;
    delete axios.defaults.headers.put["Content-Type"];

    const keys = Object.keys(payload.urls);
    const promises = keys.map(async (indexStr, index) => {
      const start = index * FILE_CHUNK_SIZE;
      const end = (index + 1) * FILE_CHUNK_SIZE;
      const blob =
        index < keys.length
          ? payload.file.slice(start, end)
          : payload.file.slice(start);
      for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
        try {
          const response = await axios.put(payload.urls[index], blob);
          commit("setUploadFinished", {
            uploadId: payload.uploadId,
            presignedUrl: payload.urls[index],
          });
          return {
            etag: response.headers.etag,
            part_number: index + 1,
          };
        } catch (error) {
          if (attempt === MAX_RETRIES - 1)
            throw new UploadError("Error uploading part", payload.uploadId); // throw error if max attempts are reached
          console.error(
            `Upload part ${index + 1} failed, attempt ${attempt + 1}:`,
            error,
          );
          await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1 second before retrying
        }
      }
    });

    return Promise.all(promises);
  },
  // Boolean to track if siteData was updated with finished upload
  isRawdataUpdated({ commit }, value) {
    commit("setRawdataUpdated", value);
  },
  // API to edit power price in power price card
  async editPowerPrice({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "editPowerPrice", value: true });
      commit("setEditPowerPriceResponse", {});
      const site_id = +payload.siteId;
      const price = payload.price;
      const date = payload.date;
      const body = {
        price: price,
        ts: date,
      };

      const res = await axios.put(`/sites/${site_id}/power-price`, body);

      if (res.status === 200) {
        commit("setEditPowerPriceResponse", res.data);
        commit("updatePowerPrice", body);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "editPowerPrice",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "editPowerPrice", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "editPowerPrice", value: false });
  },
  // Analytics APIs for analytics tab data
  async getYawMisalignmentAnalytics({ commit, dispatch, rootState }, payload) {
    try {
      commit("setLoading", {
        name: "getYawMisalignmentAnalytics",
        value: true,
      });
      commit("setMinPitchAnalytics", {});
      commit("setWindRoseAnalytics", {});
      commit("setModWindowSuitabilityAnalytics", {});
      commit("setYawMisalignmentAnalytics", {});
      const site_id = payload.site_id;
      let params = {
        user_aws_sub: rootState.user.awsSub,
        turbine_id: [],
      };

      if (payload.turbine_ids?.length > 0) {
        for (const turbineId of payload.turbine_ids) {
          if (
            turbineId === "all_turbines" ||
            turbineId.value === "all_turbines"
          ) {
            break;
          } else if (!isNaN(turbineId) || !isNaN(turbineId.value)) {
            if (turbineId.value) {
              params.turbine_id.push(turbineId.value);
            } else {
              params.turbine_id.push(turbineId);
            }
          }
        }
      }

      if (payload.start_date) {
        params.start_date = payload.start_date;
      }
      if (payload.end_date) {
        params.end_date = payload.end_date;
      }

      let turbineIdsString = "";
      if (params.turbine_id.length > 0) {
        params.turbine_id.forEach((id) => {
          turbineIdsString += `&turbine_id=${id}`;
        });
      }

      const res = await axios.get(
        `/sites/${site_id}/yaw-misalignment?user_aws_sub=${params.user_aws_sub}${turbineIdsString}`,
        {
          params,
        },
      );

      if (res.status === 200) {
        commit("setYawMisalignmentAnalytics", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getYawMisalignmentAnalytics",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getYawMisalignmentAnalytics", error },
        { root: true },
      );
    }

    commit("setLoading", {
      name: "getYawMisalignmentAnalytics",
      value: false,
    });
  },
  async getMinPitchAnalytics({ commit, dispatch, rootState }, payload) {
    try {
      commit("setLoading", {
        name: "getMinPitchAnalytics",
        value: true,
      });
      commit("setYawMisalignmentAnalytics", {});
      commit("setWindRoseAnalytics", {});
      commit("setModWindowSuitabilityAnalytics", {});
      commit("setMinPitchAnalytics", {});
      const site_id = payload.site_id;
      let params = {
        user_aws_sub: rootState.user.awsSub,
        turbine_id: [],
      };

      if (payload.turbine_ids?.length > 0) {
        for (const turbineId of payload.turbine_ids) {
          if (
            turbineId === "all_turbines" ||
            turbineId.value === "all_turbines"
          ) {
            break;
          } else if (!isNaN(turbineId) || !isNaN(turbineId.value)) {
            if (turbineId.value) {
              params.turbine_id.push(turbineId.value);
            } else {
              params.turbine_id.push(turbineId);
            }
          }
        }
      }

      if (payload.start_date) {
        params.start_date = payload.start_date;
      }
      if (payload.end_date) {
        params.end_date = payload.end_date;
      }

      let turbineIdsString = "";
      if (params.turbine_id.length > 0) {
        params.turbine_id.forEach((id) => {
          turbineIdsString += `&turbine_id=${id}`;
        });
      }

      const res = await axios.get(
        `/sites/${site_id}/min-pitch?user_aws_sub=${params.user_aws_sub}${turbineIdsString}`,
        {
          params,
        },
      );

      if (res.status === 200) {
        commit("setMinPitchAnalytics", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getMinPitchAnalytics",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getMinPitchAnalytics", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getMinPitchAnalytics", value: false });
  },
  async getWindRoseAnalytics({ commit, dispatch, rootState }, payload) {
    try {
      commit("setLoading", {
        name: "getWindRoseAnalytics",
        value: true,
      });
      commit("setYawMisalignmentAnalytics", {});
      commit("setMinPitchAnalytics", {});
      commit("setModWindowSuitabilityAnalytics", {});
      commit("setWindRoseAnalytics", {});
      const site_id = payload.site_id;
      let params = {
        user_aws_sub: rootState.user.awsSub,
      };

      if (payload.start_date) {
        params.start_date = payload.start_date;
      }
      if (payload.end_date) {
        params.end_date = payload.end_date;
      }

      const res = await axios.get(`/sites/${site_id}/wind-rose`, {
        params,
      });

      if (res.status === 200) {
        commit("setWindRoseAnalytics", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getWindRoseAnalytics",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getWindRoseAnalytics", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getWindRoseAnalytics", value: false });
  },
  async getModWindowSuitabilityAnalytics(
    { commit, dispatch, rootState },
    payload,
  ) {
    try {
      commit("setLoading", {
        name: "getModWindowSuitabilityAnalytics",
        value: true,
      });
      commit("setYawMisalignmentAnalytics", {});
      commit("setMinPitchAnalytics", {});
      commit("setWindRoseAnalytics", {});
      commit("setModWindowSuitabilityAnalytics", {});
      const site_id = payload.site_id;
      let params = {
        user_aws_sub: rootState.user.awsSub,
      };

      const res = await axios.get(`/sites/${site_id}/mod-window-suitability`, {
        params,
      });

      if (res.status === 200) {
        commit("setModWindowSuitabilityAnalytics", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getModWindowSuitabilityAnalytics",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getModWindowSuitabilityAnalytics", error },
        { root: true },
      );
    }

    commit("setLoading", {
      name: "getModWindowSuitabilityAnalytics",
      value: false,
    });
  },
  clearAnalytics({ commit }) {
    commit("setYawMisalignmentAnalytics", {});
    commit("setMinPitchAnalytics", {});
    commit("setWindRoseAnalytics", {});
    commit("setModWindowSuitabilityAnalytics", {});
  },
  async getCheckDetails({ commit, dispatch }, { siteId, issueDefId }) {
    try {
      commit("setLoading", { name: "getCheckDetails", value: true });
      commit("setSingleCheckDetails", []);

      const res = await axios.get(
        `/sites/${siteId}/checks/${issueDefId}?exclude_unconfirmed=0`,
      );

      if (res.status === 200) {
        commit("setSingleCheckDetails", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getCheckDetails",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getCheckDetails", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getCheckDetails", value: false });
  },
  async getDataStatus({ commit, dispatch }, siteId) {
    try {
      commit("setLoading", { name: "getDataStatus", value: true });
      commit("setDataStatus", {});

      const res = await axios.get(`/sites/${siteId}/data-status`);

      if (res.status === 200) {
        commit("setDataStatus", res.data);
      } else {
        commit("setDataStatus", {});
        dispatch(
          "error/raiseError",
          {
            name: "getDataStatus",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getDataStatus", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getDataStatus", value: false });
  },
  async getSiteEventCounts({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getSiteEventCounts", value: true });
      commit("setSiteEventCounts", {});
      const siteId = payload.siteId;
      const startDate = payload.startDate;
      const endDate = payload.endDate;
      const params = {
        start_ts: startDate,
        end_ts: endDate,
        limit: payload.limit,
      };

      const res = await axios.get(`/sites/${siteId}/plots/event-counts`, {
        params,
      });

      if (res.status === 200) {
        commit("setSiteEventCounts", res.data);
      } else {
        commit("setSiteEventCounts", {});
        dispatch(
          "error/raiseError",
          {
            name: "getSiteEventCounts",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSiteEventCounts", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getSiteEventCounts", value: false });
  },
  async getSiteEventDurations({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getSiteEventDurations", value: true });
      commit("setSiteEventDurations", {});
      const siteId = payload.siteId;
      const startDate = payload.startDate;
      const endDate = payload.endDate;
      const params = {
        start_ts: startDate,
        end_ts: endDate,
        limit: payload.limit,
      };

      const res = await axios.get(`/sites/${siteId}/plots/event-durations`, {
        params,
      });

      if (res.status === 200) {
        commit("setSiteEventDurations", res.data);
      } else {
        commit("setSiteEventDurations", {});
        dispatch(
          "error/raiseError",
          {
            name: "getSiteEventDurations",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSiteEventDurations", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getSiteEventDurations", value: false });
  },
};

// mutations
const mutations = {
  setSite(state, payload) {
    if (Object.keys(payload).length === 0) {
      state.siteData = {};
      // Start siteSummary with default empty values so the cards can utilize the object even if empty
      state.siteSummary = {
        site_id: payload.id,
        capacity: {
          title: "Total capacity",
          mw: 0,
          nTurbines: 0,
        },
        powerPrice: {
          title: "Power price",
          usd: "[unknown]",
          updatedAt: "N/A",
        },
        issue: {
          title: "Issues",
          open: 0,
          fixed: 0,
          attention: 0,
          resolved: 0,
          issueDefIdCount: 0,
        },
        aepPotential: {
          title: "AEP potential for open issues",
          usd: 0,
          energy: 0,
          percent: 0.0,
        },
        totalGains: {
          title: "AEP gain from fixed issues",
          usd: 0,
          energy: 0,
          percent: 0,
        },
      };
      return;
    }
    const summary = payload.issues_summary;
    // If no summary, site data is simply set to the payload
    if (!summary) {
      state.siteData = payload;
      state.siteSummary = {};
      return;
    }

    // Maintain object shape even if no subscription for uniform behavior
    if (payload.subscription === null) {
      payload.subscription = { definition: { name: null } };
    }
    const subName = payload.subscription.definition.name;
    const latestPP = payload.latest_power_price;
    let siteSummary = null;
    state.subscriptionName = `Subscription: ${subName}`;
    siteSummary = {
      site_id: payload.id,
      capacity: {
        title: "Total capacity",
        mw: payload.capacity.mw ? roundToString(payload.capacity.mw, 1) : 0,
        nTurbines: payload.capacity.n_turbines
          ? payload.capacity.n_turbines
          : 0,
      },
      powerPrice: {
        title: "Power price",
        usd: latestPP.price_upper
          ? roundToString(latestPP.price_upper, 0)
          : "[unknown]",
        updatedAt: latestPP.ts ? latestPP.ts.split("T")[0] : "N/A",
      },
      issue: {
        title: "Issues",
        open: summary.open?.n ? summary.open.n : 0,
        fixed: summary.fixed_or_resolved?.n ? summary.fixed_or_resolved.n : 0,
        attention: summary.requires_external_input?.n
          ? summary.requires_external_input.n
          : 0,
        resolved: summary.fixed_or_resolved?.n
          ? summary.fixed_or_resolved.n
          : 0,
        issueDefIdCount:
          summary.requires_external_input.turbine_issue_def_ids.length,
        unconfirmed: summary.unconfirmed?.n ? summary.unconfirmed.n : 0,
      },
      aepPotential: {
        title: "AEP potential for open issues",
        usd: summary.open.aep_loss_upper_usdpyr
          ? roundToString(summary.open.aep_loss_upper_usdpyr, -1)
          : 0,
        energy: summary.open.aep_loss_mwhpyr
          ? roundToString(summary.open.aep_loss_mwhpyr, -1)
          : 0,
        percent: summary.open.aep_loss_farm_pct
          ? roundAepPct(summary.open.aep_loss_farm_pct)
          : 0.0,
      },
      totalGains: {
        title: "AEP gain from fixed issues",
        usd: summary.fixed_or_resolved.aep_gain_upper_usdpyr
          ? roundToString(summary.fixed_or_resolved.aep_gain_upper_usdpyr, -1)
          : 0,
        energy: summary.fixed_or_resolved.aep_gain_mwhpyr
          ? roundToString(summary.fixed_or_resolved.aep_gain_mwhpyr, -1)
          : 0,
        percent: summary.fixed_or_resolved.aep_gain_farm_pct
          ? roundAepPct(summary.fixed_or_resolved.aep_gain_farm_pct)
          : 0,
      },
      cumulativeGain: {
        title: "Cumulative gain",
        usd: summary.fixed_or_resolved.gain_upper_usd
          ? roundToString(summary.fixed_or_resolved.gain_upper_usd, -1)
          : 0,
        energy: summary.fixed_or_resolved.gain_mwh
          ? roundToString(summary.fixed_or_resolved.gain_mwh, -1)
          : 0,
        percent: summary.fixed_or_resolved.gain_pct
          ? roundToString(summary.fixed_or_resolved.gain_pct, -1)
          : 0,
      },
    };
    state.siteData = payload;
    state.siteSummary = siteSummary;
  },
  clearSiteData(state) {
    state.siteData = {};
    state.turbinesData = [];
    state.turbineIds = [];
    state.documentData = [];
  },
  setTurbines(state, data) {
    if (!data.statusText) {
      const turbineIdsArr = data.map((item) => item.turbine_id);
      const turbineArr = data.map((item) => ({
        id: item.turbine_id,
        site_id: item.farm_id,
        org_id: item.org_id,
        turbine_name: item.turbine_name,
        turbine_model: item.turbine_model,
        issues_that_need_attention: item.n_require_external_input
          ? item.n_require_external_input
          : 0,
        open_issues: item.n_open ? item.n_open : 0,
        loss_upper_usd:
          item.loss_upper_usd_open !== undefined &&
          item.aep_loss_pct_open !== null
            ? Math.round(item.loss_upper_usd_open)
            : null,
        aep_loss:
          item.aep_loss_pct_open !== undefined ? item.aep_loss_pct_open : null,
        color: item.n_require_external_input > 0 ? COLORS.red : COLORS.blue,
        position: L.latLng(item.latitude_deg, item.longitude_deg),
        selected: false,
        mapInfoTitle: item.turbine_name,
        mapInfoDesc: `Issues: ${item.n_open ? item.n_open : 0}`,
        mapInfoRoute: `/turbines/${item.turbine_id}`,
        turbine_issue_ids_open: item.turbine_issue_ids_open,
        turbine_issue_ids_require_external_input:
          item.turbine_issue_ids_require_external_input,
      }));
      state.turbinesData = turbineArr;
      state.turbineIds = turbineIdsArr;
    } else {
      state.turbinesData = [];
      state.turbineIds = [];
    }
  },
  setChecks(state, payload) {
    state.checks = payload;
  },
  setPaginatedIssues(state, data) {
    state.paginatedIssuesForIssuesTable = data;
  },
  setPaginatedIssuesTotal(state, data) {
    state.paginatedIssuesTotal = data;
  },
  setIssuesForNewIssueDetail(state, data) {
    state.issuesForNewIssueDetail = data;
  },
  setSpreadsheetLoading(state, data) {
    state.spreadsheetLoading = data;
  },
  setDocumentData(state, data) {
    state.documentData = data;
  },
  setPostDataResponse(state, data) {
    state.postDataResponse = data;
  },
  setDocumentDefinitions(state, data) {
    state.documentDefinitions = data;
  },
  setSuccessDialog(state, data) {
    state.successDialog = data;
  },
  setSiteRawdata(state, data) {
    for (const item of data) {
      item.kind = setUploadType(item.kind);
    }
    state.siteRawdata = data;
  },
  addNewSiteRawdata(state, data) {
    state.isUploading = true;
    const rawDataItem = {
      id: data.upload.id,
      name: data.upload.name,
      user_email: data.upload.user_email,
      upload_start_ts: data.upload.upload_start_ts,
      status: data.upload.status,
      kind: setUploadType(data.upload.kind),
      notes: data.upload.notes,
      uploading: true,
    };
    state.siteRawdata.push(rawDataItem);
  },
  updateRawdata(state, data) {
    let found = state.siteRawdata.find((item) => item.id === data.id);
    data.kind = setUploadType(data.kind);
    if (found) {
      const foundIndex = state.siteRawdata.findIndex(
        (item) => item.id === found.id,
      );
      state.siteRawdata[foundIndex] = data;
    }
    const uploadsLoading = state.siteRawdata.filter((item) => item.uploading);
    if (uploadsLoading.length === 0) {
      state.isUploading = false;
    }
  },
  setRawdataUpdated(state, data) {
    state.rawdataUpdated = data;
  },
  setUploadProgress(state, data) {
    state.uploadProgress = data;
  },
  setUploadFinished(state, data) {
    state.uploadProgress[data.uploadId][data.presignedUrl] = true;
  },
  updatePowerPrice(state, data) {
    state.siteSummary.powerPrice.updatedAt = data.ts;
    state.siteSummary.powerPrice.usd = data.price;
  },
  setEditPowerPriceResponse(state, data) {
    state.editPowerPriceResponse = data;
  },
  setYawMisalignmentAnalytics(state, payload) {
    state.yawMisalignmentAnalytics = payload;
  },
  setModWindowSuitabilityAnalytics(state, payload) {
    state.modWindowSuitabilityAnalytics = payload;
  },
  setWindRoseAnalytics(state, payload) {
    state.windRoseAnalytics = payload;
  },
  setMinPitchAnalytics(state, payload) {
    state.minPitchAnalytics = payload;
  },
  setSingleCheckDetails(state, payload) {
    state.singleCheckDetails = payload;
  },
  setDataStatus(state, payload) {
    const yawerrorSampleMX =
      typeof payload.yawerror_3d_mn_sample_period_mx === "number"
        ? payload.yawerror_3d_mn_sample_period_mx
        : "N/A";
    const insufficientSignalCnt =
      typeof payload.insufficient_dir_signals_cnt === "number"
        ? payload.insufficient_dir_signals_cnt
        : "N/A";
    const scadaCompleteness90d =
      typeof payload.scada_completeness_90d_pct_mn === "number"
        ? payload.scada_completeness_90d_pct_mn.toFixed(1)
        : "N/A";

    if (Object.keys(payload).length > 0) {
      let dataMetrics = [
        {
          title: "High frequency data",
          staleness_level: payload.turbine_scada_hf_staleness,
          body: [
            `First timestamp: ${
              payload.first_scada_ts
                ? formatTimestamp(payload.first_scada_ts)
                : "N/A"
            }`,
            `Last timestamp: ${
              payload.last_scada_ts
                ? formatTimestamp(payload.last_scada_ts)
                : "N/A"
            }`,
            `Yaw error max sample period (3d): ${yawerrorSampleMX} ${
              yawerrorSampleMX === "N/A" ? "" : "s"
            }`,
            `Turbines with insufficient direction signals: ${insufficientSignalCnt}`,
            `Completeness (90d): ${scadaCompleteness90d}${
              scadaCompleteness90d === "N/A" ? "" : "%"
            }`,
          ],
          site_id: payload.site_id,
        },
        {
          title: "10 minute data",
          staleness_level: payload.turbine_raw_10m_staleness,
          body: [
            `First timestamp: ${
              payload.first_raw_10m_ts
                ? formatTimestamp(payload.first_raw_10m_ts)
                : "N/A"
            }`,
            `Last timestamp: ${
              payload.last_raw_10m_ts
                ? formatTimestamp(payload.last_raw_10m_ts)
                : "N/A"
            }`,
          ],
          site_id: payload.site_id,
        },
        {
          title: "Event data",
          staleness_level: payload.turbine_event_staleness,
          body: [
            `First timestamp: ${
              payload.first_event_ts
                ? formatTimestamp(payload.first_event_ts)
                : "N/A"
            }`,
            `Last timestamp: ${
              payload.last_event_ts
                ? formatTimestamp(payload.last_event_ts)
                : "N/A"
            }`,
          ],
          site_id: payload.site_id,
        },
      ];

      state.statusCards = dataMetrics;
    } else {
      state.statusCards = [];
    }
  },
  resetSiteData(state) {
    state.turbinesData = [];
    state.turbineIds = [];
    state.checks = [];
    state.paginatedIssuesForIssuesTable = [];
    state.documentData = null;
    state.siteRawdata = [];
    // state.singleCheckDetails = {};
    state.statusCards = [];
  },
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setAbortControllerPaginatedIssues(state) {
    state.abortControllerPaginatedIssues = new AbortController();
  },
  setSiteEventCounts(state, payload) {
    state.siteEventCounts = payload;
  },
  setSiteEventDurations(state, payload) {
    state.siteEventDurations = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
