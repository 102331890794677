import axios from "axios";

export async function getPlotlyData(url) {
  let message = "";

  try {
    const res = await axios.get(url);

    if (res.status === 200) {
      return {
        status: "success",
        data: res.data,
      };
    }
  } catch (error) {
    message = error?.response?.data?.message || error?.message;
  }

  return {
    status: "failure",
    message,
  };
}
