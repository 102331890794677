export const mapTileLayerUrl = `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?
access_token=pk.eyJ1IjoicGFydGlzYW5waXhlbCIsImEiOiJjajB0dGJ5ZmkwNTh4MnducHBsOTVpdGI4In0.0LRoz0WYs5WkxWLLysJsFQ`;
export const darkMapTileLayerUrl = `https://api.mapbox.com/styles/v1/mapbox/dark-v11/tiles/{z}/{x}/{y}?
access_token=pk.eyJ1IjoicGFydGlzYW5waXhlbCIsImEiOiJjajB0dGJ5ZmkwNTh4MnducHBsOTVpdGI4In0.0LRoz0WYs5WkxWLLysJsFQ`;

export const baseAPI = process.env.VUE_APP_WE_BASE_API;

export const headers = {
  "X-API-Key": process.env.VUE_APP_WE_API_KEY,
  "Content-Type": "application/json",
};

export const contactUsUrl =
  "https://share.hsforms.com/1NkJoOhTOQOCvitpiaWmzkA4akwl";

export const knowledgeBaseUrl = "https://7212117.hs-sites.com/knowledge";

export const portalTrainingBaseUrl =
  "https://7212117.hs-sites.com/knowledge/portal-training";

export const allSubscriptions = [
  { name: "Pulse", sow_def_id: 1 },
  { name: "New POC assessment (retired)", sow_def_id: 2 },
  { name: "WeBoost POC (retired)", sow_def_id: 3 },
  { name: "Energy improvement", sow_def_id: 5 },
  { name: "Static yaw fix", sow_def_id: 6 },
  { name: "Data integration", sow_def_id: 7 },
  { name: "Swarm assessment", sow_def_id: 8 },
  { name: "WeProtect (retired)", sow_def_id: 9 },
  { name: "Swarm", sow_def_id: 10 },
  { name: "Pulse Basic", sow_def_id: 11 },
];

export const EmptyPreviewContent = "Nothing to preview";

export const statusColors = {
  blue: "#CADCEE",
  gray: "#D5D5D8",
  green: "#DAECE5",
  red: "#F6E0E0",
  yellow: "#FFEACB",
};

export const generateStatusColor = (value) => {
  switch (value) {
    case 1:
      return statusColors.gray;
    case 2:
      return statusColors.yellow;
    case 3:
      return statusColors.yellow;
    case 4:
      return statusColors.yellow;
    case 5:
      return statusColors.yellow;
    case 6:
      return statusColors.yellow;
    case 7:
      return statusColors.green;
    case 8:
      return statusColors.green;
    case 9:
      return statusColors.green;
    case 10:
      return statusColors.gray;
    case 11:
      return statusColors.red;
    case 12:
      return statusColors.yellow;
    case 13:
      return statusColors.gray;
    case 14:
      return statusColors.red;
    case 16:
      return statusColors.red;

    default:
      return statusColors.gray;
  }
};

export const generateTypeColors = (value) => {
  switch (value) {
    case "Plant-wide control assessment":
      return statusColors.green;
    case "Plant-level control assessment":
      return statusColors.blue;
    case "Miscellaneous":
      return statusColors.yellow;
    default:
      return statusColors.gray;
  }
};

export const issueStatusOptions = [
  {
    title: "Unconfirmed (not visible)",
    icon: "mdi-close-circle-outline",
    color: "gray",
    backgroundColor: statusColors.gray,
    id: 1,
  },
  {
    title: "Needs review",
    icon: "mdi-close-circle-outline",
    color: "gray",
    backgroundColor: statusColors.gray,
    id: 16,
  },
  {
    title: "New",
    icon: "mdi-check-circle-outline",
    color: "yellow",
    backgroundColor: statusColors.yellow,
    id: 2,
  },
  {
    title: "In progress",
    icon: "mdi-check-circle-outline",
    color: "yellow",
    backgroundColor: statusColors.yellow,
    id: 3,
  },
  {
    title: "Actionable, awaiting recommendation",
    icon: "mdi-check-circle-outline",
    color: "yellow",
    backgroundColor: statusColors.yellow,
    id: 4,
  },
  {
    title: "Pending implementation",
    icon: "mdi-check-circle-outline",
    color: "yellow",
    backgroundColor: statusColors.yellow,
    id: 5,
  },
  {
    title: "Implemented, pending verification",
    icon: "mdi-check-circle-outline",
    color: "yellow",
    backgroundColor: statusColors.yellow,
    id: 6,
  },
  {
    title: "Resolved",
    icon: "mdi-check-circle-outline",
    color: "green",
    backgroundColor: statusColors.green,
    id: 7,
  },
  {
    title: "Fixed",
    icon: "mdi-check-circle-outline",
    color: "green",
    backgroundColor: statusColors.green,
    id: 8,
  },
  {
    title: "Fixed + measured",
    icon: "mdi-check-circle-outline",
    color: "green",
    backgroundColor: statusColors.green,
    id: 9,
  },
  {
    title: "Deferred",
    icon: "mdi-close-circle-outline",
    color: "gray",
    backgroundColor: statusColors.gray,
    id: 10,
  },
  {
    title: "Dismissed",
    icon: "mdi-close-circle-outline",
    color: "red",
    backgroundColor: statusColors.red,
    id: 11,
  },
  {
    title: "Waiting for good mod window",
    icon: "mdi-check-circle-outline",
    color: "yellow",
    backgroundColor: statusColors.yellow,
    id: 12,
  },
  {
    title: "Superseded",
    icon: "mdi-close-circle-outline",
    color: "gray",
    backgroundColor: statusColors.gray,
    id: 13,
  },
  {
    title: "Invalidated",
    icon: "mdi-close-circle-outline",
    color: "red",
    backgroundColor: statusColors.red,
    id: 14,
  },
  {
    title: "Not visible",
    icon: "mdi-close-circle-outline",
    color: "gray",
    backgroundColor: statusColors.gray,
  },
  {
    title: "Awaiting recommendation",
    icon: "mdi-close-circle-outline",
    color: "gray",
    backgroundColor: statusColors.gray,
  },
  {
    title: "Implemented",
    icon: "mdi-check-circle-outline",
    color: "green",
    backgroundColor: statusColors.green,
  },
  {
    title: "Pending verification",
    icon: "mdi-check-circle-outline",
    color: "yellow",
    backgroundColor: statusColors.yellow,
  },
  {
    title: "Dismissed (internal)",
    icon: "mdi-close-circle-outline",
    color: "red",
    backgroundColor: statusColors.red,
    id: 15,
  },
];

export const notificationStyles = [
  {
    title: "farm_document",
    icon: "mdi-file-upload-outline",
    color: "rgb(13, 149, 235, 0.5)",
  },
  {
    title: "turbine_issue_status",
    icon: "mdi-wind-turbine-alert",
    color: "yellow",
  },
  {
    title: "turbine_issue_status-fixed",
    icon: "mdi-wind-turbine-check",
    color: "green",
  },
  {
    title: "turbine_issue_comment",
    icon: "mdi-message-text-outline",
    color: "grey",
  },
  {
    title: "alert-unaddressed",
    icon: "mdi-alert-circle-outline",
    color: "red",
  },
  {
    title: "alert-pending",
    icon: "mdi-alert-circle-outline",
    color: "yellow",
  },
  {
    title: "alert-resolved",
    icon: "mdi-alert-circle-check-outline",
    color: "green",
  },
];

export const informationIcon = "mdi-information-outline";

export const popupDelay = "500";
export const summaryWidgetSpinnerSize = "30";
export const clickCountLimit = 10;

export const isChina = window.location.href.includes(".cn");
export const icpNotice = "京ICP备2022028133号-1";

export const otherTestStatuses = [];

export const rotorShaftDescription =
  "The main shaft assembly of a wind turbine connects " +
  "the hub with the gearbox, or the generator in the case of direct-drive turbines. The main " +
  "shaft assembly includes one shaft with one main bearing for single-bearing configurations " +
  "and two main bearings for dual configurations. The number of bearings necessary is determined " +
  "by the type of turbine configuration. Main bearings, also known as rotor bearings, have been " +
  "identified as one of the most critical components in terms of increasing reliability and " +
  "availability for the transmission system of a wind turbine. WindESCo adopts a comprehensive " +
  "approach to monitor main bearing health, utilizing all available data sources. The SCADA-based " +
  "approach is particularly prevalent, employing a machine-learning model that leverages the " +
  "inherent physical correlation between friction and temperature to detect potential issues " +
  "in the main bearings.";

export const converterDescription = {
  description1:
    "A wind turbine converter converts the variable" +
    " frequency output of the generator into a fixed frequency appropriate for" +
    " grid or load. The configuration of a converter can vary broadly based on the" +
    " turbine configuration. ",
  description2:
    "As a part of the electrical drivetrain, a converter is" +
    " an important element of turbine efficiency and also can be a leading indicator" +
    " of other turbine issues. ",
  description3:
    "The converter is monitored for various stress indexes" +
    " to determine the asset health.",
};

export const rowOptions = [
  {
    text: "5",
    value: 5,
  },
  {
    text: "10",
    value: 10,
  },
  {
    text: "15",
    value: 15,
  },
  {
    text: "20",
    value: 20,
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "50",
    value: 50,
  },
  {
    text: "All",
    value: "All",
  },
];

export const riskFactorsDescriptions = [
  {
    title: "Stress index:",
    first:
      " it indicates the stress from environmental" +
      " conditions by taking into account a variety of factors to estimate the" +
      " corresponding stress on the monitored unit.",
    second:
      "Two major factors are considered: overheating and dampness," +
      " which can lead to failure either instantaneously or with a delay.",
    third:
      "The environmental condition definitions align with the most" +
      " recent standards (i.e., Climatic Condition IEC 60721-3-3 and Corrosion" +
      " Risk IEC 60721-3-3)",
  },
  {
    title: "Reliability index:",
    first:
      " it indicates how safe it is to continue" +
      " operation considering different sources of potential risk. It is a" +
      " forward-looking condition index that combines the history of failures" +
      " and operational anomalies.",
    indicators: ["Red", "Yellow", "Green"],
    Red: " the unit was in a fault state at least once during that day.",
    Yellow:
      " the unit did not enter a fault state but there are active" +
      " warnings.",
    Green: " the unit was operational without faults or warnings.",
  },
  {
    title: "Remaining useful life (RUL):",
    first:
      " the unit/component's lifetime is" +
      " estimated using a statistical approach combined with measurements of" +
      " main stressors. The RUL can be used to plan maintenance activities" +
      " in advance.",
    second:
      "Users should be aware that such a statistical approach cannot" +
      " predict all types of failures.",
  },
];

export const eventCountsPlotDescription = [
  "Each row represents a different event type.",
  "Each colored segment of a bar represents a turbine.",
  "The length of each segment represents the number of events" +
    " detected for that turbine in a given month.",
];

export const eventDurationsPlotDescription = [
  "Each row represents a different event type.",
  "Each colored segment of a bar represents a turbine.",
  "The length of each segment represents the total duration of" +
    " an event detected for that turbine in a given month.",
];
