import axios from "@/helpers/axiosAPI";

// initial state
const state = () => ({
  loading: {
    getSummary: false,
    getChecks: false,
    getIssuesGroupedByIssue: false,
    getIssuesGroupedBySite: false,
    getIssuesGroupedByOrg: false,
    getIssuesGroupedByOEM: false,
    getPaginatedIssuesForIssuesTable: false,
  },
  summary: {},
  checks: [],
  issuesGroupedByIssues: [],
  issuesGroupedByOrg: [],
  issuesGroupedBySite: [],
  issuesGroupedByOEM: [],
  paginatedIssuesForIssuesTable: [],
  issuesTotal: 0,
  abortControllerPaginatedIssues: new AbortController(),
  abortControllerChecks: new AbortController(),
});

// actions
const actions = {
  async getSummary({ commit, dispatch }) {
    try {
      commit("setLoading", {
        name: "getSummary",
        value: true,
      });
      let params = {
        exclude_unconfirmed: false,
        get_losses_gains: true,
        summarize: 1,
      };

      const res = await axios.get("/turbine-issues", {
        params,
      });

      if (res.status === 200) {
        commit("setSummary", res.data);
      } else {
        commit("setSummary", []);
        dispatch(
          "error/raiseError",
          {
            name: "getSummary",
            ...{
              code: res.status,
              message: res.data,
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSummary", error },
        { root: true },
      );
    }
    commit("setLoading", {
      name: "getSummary",
      value: false,
    });
  },
  async getChecks({ commit, dispatch, rootState }) {
    try {
      // Cancel the previous request
      rootState.home.abortControllerChecks.abort();
      // Create a new abort controller for the current request
      commit("setAbortControllerChecks");
      commit("setLoading", { name: "getChecks", value: true });
      commit("setChecks", []);
      const params = {
        group_by: "issue",
        get_losses_gains: true,
        exclude_unconfirmed: false,
      };

      const res = await axios.get("/turbine-issues", {
        signal: rootState.home.abortControllerChecks.signal,
        params,
      });

      if (res.status === 200) {
        commit("setChecks", res.data.results);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getChecks",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getChecks", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getChecks", value: false });
  },
  async getIssuesGroupedByIssue({ commit, dispatch }) {
    try {
      commit("setLoading", {
        name: "getIssuesGroupedByIssue",
        value: true,
      });

      const res = await axios.get("/turbine-issues", {
        params: { group_by: "issue" },
      });

      if (res.status === 200) {
        commit("setIssuesGroupedByIssue", res.data.results);
      } else {
        commit("setIssuesGroupedByIssue", []);
        dispatch(
          "error/raiseError",
          {
            name: "getIssuesGroupedByIssue",
            ...{
              code: res.status,
              message: res.data,
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getIssuesGroupedByIssue", error },
        { root: true },
      );
    }
    commit("setLoading", {
      name: "getIssuesGroupedByIssue",
      value: false,
    });
  },
  async getIssuesGroupedBySite({ commit, dispatch }) {
    try {
      commit("setLoading", {
        name: "getIssuesGroupedBySite",
        value: true,
      });

      const res = await axios.get("/turbine-issues", {
        params: { group_by: "site" },
      });

      if (res.status === 200) {
        commit("setIssuesGroupedBySite", res.data.results);
      } else {
        commit("setIssuesGroupedBySite", []);
        dispatch(
          "error/raiseError",
          {
            name: "getIssuesGroupedBySite",
            ...{
              code: res.status,
              message: res.data,
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getIssuesGroupedBySite", error },
        { root: true },
      );
    }
    commit("setLoading", {
      name: "getIssuesGroupedBySite",
      value: false,
    });
  },
  async getIssuesGroupedByOrg({ commit, dispatch }) {
    try {
      commit("setLoading", {
        name: "getIssuesGroupedByOrg",
        value: true,
      });
      let params = {
        exclude_unconfirmed: false,
        get_losses_gains: true,
        group_by: "org",
      };

      const res = await axios.get("/turbine-issues", {
        params,
      });

      if (res.status === 200) {
        commit("setIssuesGroupedByOrg", res.data.results);
      } else {
        commit("setIssuesGroupedByOrg", []);
        dispatch(
          "error/raiseError",
          {
            name: "getIssuesGroupedByOrg",
            ...{
              code: res.status,
              message: res.data,
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getIssuesGroupedByOrg", error },
        { root: true },
      );
    }
    commit("setLoading", {
      name: "getIssuesGroupedByOrg",
      value: false,
    });
  },
  async getIssuesGroupedByOEM({ commit, dispatch }) {
    try {
      commit("setLoading", {
        name: "getIssuesGroupedByOEM",
        value: true,
      });
      let params = {
        exclude_unconfirmed: false,
        get_losses_gains: true,
        group_by: "manufacturer",
      };

      const res = await axios.get("/turbine-issues", {
        params,
      });

      if (res.status === 200) {
        commit("setIssuesGroupedByOEM", res.data.results);
      } else {
        commit("setIssuesGroupedByOEM", []);
        dispatch(
          "error/raiseError",
          {
            name: "getIssuesGroupedByOEM",
            ...{
              code: res.status,
              message: res.data,
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getIssuesGroupedByOEM", error },
        { root: true },
      );
    }
    commit("setLoading", {
      name: "getIssuesGroupedByOEM",
      value: false,
    });
  },
  async getPaginatedIssuesForIssuesTable(
    { commit, dispatch, rootState },
    payload,
  ) {
    try {
      // Cancel the previous request
      rootState.home.abortControllerPaginatedIssues.abort();
      // Create a new abort controller for the current request
      commit("setAbortControllerPaginatedIssues");
      commit("setLoading", {
        name: "getPaginatedIssuesForIssuesTable",
        value: true,
      });
      commit("setPaginatedIssues", []);
      let includeStatusString = "";
      const itemsPerPage = parseInt(payload.items_per_page);
      const page = parseInt(payload.page);
      const sortDesc = parseInt(payload.sort_desc);
      const params = {
        summarize: 0,
      };
      params.limit = itemsPerPage;
      params.offset = (page - 1) * itemsPerPage;
      params.sort_desc = sortDesc;
      params.group_by = null;
      if (payload.sort_by) {
        params.sort_by = payload.sort_by;
      }

      if ("include_statuses" in payload) {
        let statuses = [];
        if (payload.include_statuses.length > 0) {
          for (const item of payload.include_statuses) {
            if (item.includes("_")) {
              const splitItem = item.split("_");
              const status = `${splitItem[0]} ${splitItem[1]}`;
              statuses.push(status);
            } else {
              statuses.push(item);
            }
          }
          statuses.forEach((status) => {
            includeStatusString += `&include_status=${status}`;
          });
        } else {
          // Empty all vars and release loading if no statuses supplied
          commit("setPaginatedIssues", []);
          commit("setIssuesTotal", 0);
          commit("setLoading", {
            name: "getPaginatedIssuesForIssuesTable",
            value: false,
          });
          return;
        }
      }

      if (payload.search_for) {
        params.search_for = payload.search_for;
      }

      const res = await axios.get(`/turbine-issues?${includeStatusString}`, {
        signal: rootState.home.abortControllerPaginatedIssues.signal,
        params,
      });

      if (res.status === 200) {
        let data = res.data.results.map((item) => ({
          ...item,
          loss_upper_usd: item.aep_loss_pct < 0.05 ? 0 : item.loss_upper_usd,
        }));
        commit("setPaginatedIssues", data);
        commit("setIssuesTotal", res.data.total);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getPaginatedIssuesForIssuesTable",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      if (error.name === "AbortError") {
        commit("setLoading", {
          name: "getPaginatedIssuesForIssuesTable",
          value: false,
        });
        return;
      } else {
        dispatch(
          "error/raiseError",
          { name: "getPaginatedIssuesForIssuesTable", error },
          { root: true },
        );
      }
    }
    commit("setLoading", {
      name: "getPaginatedIssuesForIssuesTable",
      value: false,
    });
  },
};

// mutations
const mutations = {
  setSummary(state, data) {
    state.summary = data;
  },
  setChecks(state, data) {
    state.checks = data;
  },
  setIssuesGroupedByIssue(state, data) {
    state.issuesGroupedByIssue = data;
  },
  setIssuesGroupedBySite(state, data) {
    state.issuesGroupedBySite = data;
  },
  setIssuesGroupedByOrg(state, data) {
    state.issuesGroupedByOrg = data;
  },
  setIssuesGroupedByOEM(state, data) {
    state.issuesGroupedByOEM = data;
  },
  setPaginatedIssues(state, data) {
    state.paginatedIssuesForIssuesTable = data;
  },
  setIssuesTotal(state, data) {
    state.issuesTotal = data;
  },
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setAbortControllerChecks(state) {
    state.abortControllerChecks = new AbortController();
  },
  setAbortControllerPaginatedIssues(state) {
    state.abortControllerPaginatedIssues = new AbortController();
  },
  reset(state) {
    state.summary = {};
    state.checks = [];
    state.issuesGroupedByOrg = [];
    state.issuesGroupedBySite = [];
    state.issuesGroupedByOEM = [];
    state.paginatedIssuesForIssuesTable = [];
    state.issuesTotal = 0;
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
