import Vue from "vue";
import "core-js/stable";
import "regenerator-runtime/runtime";
import store from "./store";
import { vuetify } from "./plugins";
import App from "./App";
import router from "./router";
import { iconsSet as icons } from "./assets/icons/icons.js";
import VuePapaParse from "vue-papa-parse";
import VueGtag from "vue-gtag";
import "leaflet/dist/leaflet.css";
import "vue2-datepicker/index.css";
import "vue-select/dist/vue-select.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "vue-tel-input/dist/vue-tel-input.css";

const url = new URL(window.location.href);
Vue.config.productionTip = false;
const gaConfig = {
  pageTrackerScreenviewEnabled: true,
};
if (
  process.env.NODE_ENV === "development" ||
  url.host === "app-dev.windesco.com"
) {
  gaConfig.config = { id: "G-ZJB67B05YT" };
  gaConfig.appName = "WindESCo Portal Development";
  Vue.config.performance = true;
} else if (process.env.NODE_ENV === "production") {
  gaConfig.config = { id: "G-271QNPG6LE" };
  gaConfig.appName = "WindESCo Portal Production";
}
// Connect the current data stream (production or development) to google analytics
Vue.use(VueGtag, gaConfig);
Vue.use(VuePapaParse);
// Create a shortcut for console.log ($log)
Vue.prototype.$log = console.log.bind(console);

new Vue({
  el: "#app",
  router,
  store,
  vuetify,
  icons,
  render: (h) => h(App),
}).$mount("#app");
