import axios from "@/helpers/axiosAPI";
import { getPlotlyData } from "@/services/issueService";

const storedEvidences = localStorage.getItem("staticCombinedEvidences");
let initial = [];
if (storedEvidences) {
  initial = JSON.parse(storedEvidences);
}

// initial state
const state = () => ({
  loading: {
    getEvidences: false,
    getCurrentEvidences: false,
  },
  evidences: [],
  cachedEvidence: [],
  combinedEvidences: [],
  staticCombinedEvidences: initial,
});

// actions
const actions = {
  async getEvidences({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "getEvidences", value: true });

      const res = await axios.get(
        `/turbine-issue-evidence/${payload.evidenceId}`,
      );

      if (res.status === 200) {
        // Create predetermined array length for evidences according to evidenceId array length
        const params = {
          lengthOfIds: payload.lengthOfIds,
          index: payload.evidenceIndex,
          data: res.data,
        };
        commit("setEvidences", params);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getEvidences",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getEvidences", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getEvidences", value: false });
  },
  cacheEvidence({ commit }, payload) {
    commit("pushEvidence", payload);
  },
  clearEvidences({ commit }) {
    commit("clearEvidences");
  },
  async getCurrentEvidence({ commit, dispatch, rootState }, payload) {
    try {
      commit("setLoading", { name: "getCurrentEvidence", value: true });
      commit("setCombinedEvidences", []);

      const res = await axios.get(
        `/turbine-issues/${payload.turbineIssueId}/evidence/live`,
      );

      if (res.status === 200) {
        let currentEvidences = res.data;
        let evidenceList = [];
        // Get the plotly data for each live evidence
        for (const item of currentEvidences) {
          let plotlyData = null;
          if (item.file_type.includes("plotly")) {
            const res = await getPlotlyData(item.uri);
            if (res.status === "success") {
              plotlyData = res.data;
            }
          }
          item.type = item.file_type;
          item.plotlyData = plotlyData;
          item.isCurrent = true;
        }
        // Reset static evidences if the are not for the current turbine issue id
        if (rootState.evidence.staticCombinedEvidences.length > 0) {
          if (
            rootState.evidence.staticCombinedEvidences[0]?.snapshot
              .turbine_issue_id !== payload.turbineIssueId
          ) {
            dispatch("clearStaticCombinedEvidence", { root: true });
          }
        }
        // Find the matches between current and snapshot
        for (const evidence of payload.evidences) {
          let hasMatch = false;
          let evidenceObj = {};
          evidence.turbine_issue_id = payload.turbineIssueId;
          for (const currEvidence of currentEvidences) {
            if (
              (evidence.name === currEvidence.name ||
                evidence.title === currEvidence.title) &&
              evidence.internal === currEvidence.internal
            ) {
              hasMatch = true;
              currEvidence.hasMatch = true;
              evidenceObj.current = currEvidence;
              evidenceObj.current.hasMatch = true;
              evidenceObj.current.show = false;
              evidenceObj.snapshot = evidence;
              evidenceObj.snapshot.hasMatch = true;
              evidenceObj.snapshot.show = true;
              break;
            }
          }
          // Set object to have legacy only data for no match
          if (!hasMatch) {
            evidenceObj.current = null;
            evidenceObj.snapshot = evidence;
            evidenceObj.snapshot.show = true;
          }
          evidenceList.push(evidenceObj);
        }
        // Add the unmatched live evidence to the evidence list
        for (const currEvidence of currentEvidences) {
          let evidenceObj = {};
          if (!currEvidence.hasMatch) {
            evidenceObj.current = currEvidence;
            evidenceObj.current.show = true;
            evidenceObj.snapshot = null;
            evidenceList.push(evidenceObj);
          }
        }

        // Put the evidence objects that contain both legacy and live evidence objects in front of array
        const reorderedEvidenceList = evidenceList.sort((a, b) => {
          const aHasBoth = a.current && a.snapshot;
          const bHasBoth = b.current && b.snapshot;

          if (aHasBoth && !bHasBoth) {
            return -1; // a has both live and legacy objects populated
          } else if (!aHasBoth && bHasBoth) {
            return 1; // b has both live and legacy objects populated
          }
          return 0; // No change in ordering
        });

        commit("setCombinedEvidences", reorderedEvidenceList);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getCurrentEvidence",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getCurrentEvidence", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getCurrentEvidence", value: false });
  },
  setStaticCombinedEvidence({ commit }, payload) {
    commit("setStaticEvidenceItems", payload);
  },
  clearStaticCombinedEvidence({ commit }) {
    commit("clearStaticCombinedEvidences");
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setEvidences(state, payload) {
    if (state.evidences.length === 0) {
      state.evidences = Array(payload.lengthOfIds);
    }
    state.evidences[payload.index] = payload.data;
  },
  clearEvidences(state) {
    state.evidences = [];
  },
  pushEvidence(state, payload) {
    state.cachedEvidence.push(payload);
  },
  setCombinedEvidences(state, payload) {
    state.combinedEvidences = payload;
  },
  setStaticEvidenceItems(state, payload) {
    let staticArray = [];
    for (const evidence of payload.evidenceArray) {
      const evidenceObj = { ...evidence };
      staticArray.push(evidenceObj);
    }
    const evidenceItem = staticArray[payload.index];
    if (payload.current === "show") {
      evidenceItem.current.show = true;
      evidenceItem.snapshot.show = false;
    } else {
      evidenceItem.snapshot.show = true;
      evidenceItem.current.show = false;
    }
    localStorage.setItem(
      "staticCombinedEvidences",
      JSON.stringify(staticArray),
    );
    state.staticCombinedEvidences = JSON.parse(
      localStorage.getItem("staticCombinedEvidences"),
    );
  },
  clearStaticCombinedEvidences() {
    localStorage.setItem("staticCombinedEvidences", JSON.stringify([]));
  },
  // Called from issue-detail action to update an evidence item from edit
  setEditedEvidenceItem(state, payload) {
    const index = payload.evidenceIndex;
    const evidence = payload.evidence;
    const stateEvidence = state.combinedEvidences[index];
    if (stateEvidence.current && stateEvidence.current.show) {
      stateEvidence.current.internal = evidence.internal;
      stateEvidence.current.description = evidence.description;
      stateEvidence.current.title = evidence.title;
    } else if (stateEvidence.snapshot && stateEvidence.snapshot.show) {
      stateEvidence.snapshot.internal = evidence.internal;
      stateEvidence.snapshot.description = evidence.description;
      stateEvidence.snapshot.title = evidence.title;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
