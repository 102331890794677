import ax from "axios";
import jwt_decode from "jwt-decode";
import store from "../store";
import router from "../router";

/**
  Create an axios client.
  This client is smart enough to add Authorization token before
  calling api.
  Also refresh token whenever required.
*/
const axios = ax.create({
  baseURL: process.env.VUE_APP_WE_BASE_API,
  headers: {
    Accept: "application/json",
    "x-api-key": process.env.VUE_APP_WE_API_KEY,
  },
  withCredentials: false,
});

export let abortController = new AbortController();

axios.interceptors.request.use(
  async function (config) {
    abortController = new AbortController();
    let token;
    if (!["/auth", "/user/password", "/auth/refresh"].includes(config.url)) {
      try {
        token = await getToken();
      } catch (error) {
        if (error.response.status === 401) {
          console.info("401 error:", error);
          store.dispatch("user/logout");
          router.push({ name: "Login" });
        }
      }
    }

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      return {
        ...config,
        signal: abortController.signal,
      };
    }
    return config;
  },
  function (error) {
    if (error.response.status === 401) {
      console.info("401 error:", error);

      store.dispatch("user/logout");
      router.push({ name: "Login" });
    }
    return Promise.reject(error);
  },
);

async function getToken() {
  return new Promise((resolve, reject) => {
    try {
      const baseURL = process.env.VUE_APP_WE_BASE_API;
      const header = {
        "Content-Type": "application/json",
        "x-api-key": process.env.VUE_APP_WE_API_KEY,
      };

      // First check if stored token is still valid, and use if so
      let token = localStorage.getItem("id_token");
      if (token == null) token = localStorage.getItem("access_token");
      if (token) {
        const decoded = jwt_decode(token);
        const now = Date.now() / 1000;
        if (now < decoded.exp) {
          return resolve(token);
        }
      }

      const refresh_token = localStorage.getItem("refresh_token");

      if (!refresh_token) {
        return resolve(null);
      }

      const ax2 = ax.create({
        baseURL: baseURL,
        header,
        withCredentials: false,
      });

      const body = {
        refresh_token: refresh_token,
      };

      ax2
        .post(`${baseURL}/auth/refresh`, body, {
          header,
        })
        .then(async (res) => {
          /*
            Store the tokens in local storage
          */
          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem("id_token", res.data.id_token);
          return resolve(res.data.id_token);
        })
        .catch((err) => {
          console.info("Token refresh error:", err);
          return reject(err);
        });
    } catch (err) {
      return reject(err);
    }
  });
}

export default axios;
