import axios from "@/helpers/axiosAPI";
import Axios from "axios";
import { uploadFileToS3, setUploadType, getFile } from "@/helpers/functions";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

// initial state
const state = () => ({
  turbines: [],
  turbineIds: [],
  editPowerPriceResponse: {},
  siteRawdata: [],
  rawdataUpdated: false,
  siteResponse: {},
  site: {},
  allSites: [],
  loading: {
    setPowerPrice: false,
    getTurbinesBySiteId: false,
    addNewSite: false,
    getSite: false,
    getAllSites: false,
    editPowerPrice: false,
    updateSite: false,
    postSiteRawdata: false,
    getSiteRawdata: false,
  },
  powerPrice: {},
  isUploading: false,
  uploadProgress: {},
});

// actions
const actions = {
  async getSite({ commit, dispatch, rootState }, siteId) {
    try {
      commit("setLoading", { name: "getSite", value: true });
      commit("setSite", {});

      const params = { user_aws_sub: rootState.user.awsSub };

      const res = await axios.get(`/sites/${siteId}`, {
        params,
      });

      if (res.status === 200) {
        commit("setSite", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getSite",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch("error/raiseError", { name: "getSite", error }, { root: true });
    }

    commit("setLoading", { name: "getSite", value: false });
  },
  async getAllSites({ commit, dispatch, rootState }) {
    try {
      commit("setLoading", { name: "getAllSites", value: true });
      commit("setAllSites", []);

      const params = { user_aws_sub: rootState.user.awsSub };

      const res = await axios.get(`/sites`, {
        params,
      });

      if (res.status === 200) {
        commit("setAllSites", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getAllSites",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getAllSites", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getAllSites", value: false });
  },
  async getTurbinesBySiteId(
    { commit, dispatch },
    { siteId, offset, limit, sortColumn, sortOrder },
  ) {
    try {
      commit("setLoading", { name: "getTurbinesBySiteId", value: true });
      commit("setTurbines", []);
      const paginationParams = {
        offset,
        limit,
      };

      if (sortColumn && sortOrder) {
        paginationParams.sort_column = sortColumn;
        paginationParams.sort_order = sortOrder;
      }

      const params = {
        group_by: "turbine",
        exclude_unconfirmed: false,
      };

      const res = await axios.get(`/sites/${siteId}/turbine-issues`, {
        params,
      });

      if (res.status === 200) {
        commit("setTurbines", res.data.results);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getTurbinesBySiteId",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getTurbinesBySiteId", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getTurbinesBySiteId", value: false });
  },
  async getPowerPrice({ commit, dispatch }, siteId) {
    try {
      commit("setLoading", { name: "getPowerPrice", value: true });
      commit("setPowerPrice", []);

      const res = await axios.get(`/sites/${siteId}/power-price`);

      if (res.status === 200) {
        commit("setPowerPrice", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getPowerPrice",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getPowerPrice", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getPowerPrice", value: false });
  },
  async editPowerPrice({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "setPowerPrice", value: true });
      commit("setEditPowerPriceResponse", {});

      const site_id = +payload.siteId;
      const price = payload.price;
      const date = payload.date;

      const body = {
        price: price,
        ts: date,
      };

      const res = await axios.put(`/sites/${site_id}/power-price`, body);

      if (res.status === 200) {
        commit("setEditPowerPriceResponse", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "editPowerPrice",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "editPowerPrice", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "setPowerPrice", value: false });
  },
  async getSiteRawdata({ commit, dispatch, rootState }, siteId) {
    try {
      commit("setSiteRawdata", []);
      commit("setLoading", { name: "getSiteRawdata", value: true });
      const params = {
        user_aws_sub: rootState.user.awsSub,
      };

      const res = await axios.get(`/sites/${siteId}/data/raw`, {
        params,
      });

      if (res.status === 200) {
        commit("setSiteRawdata", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getSiteRawdata",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getSiteRawdata", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getSiteRawdata", value: false });
  },
  async postSiteRawdata({ commit, dispatch }, payload) {
    try {
      commit("setLoading", { name: "postSiteRawdata", value: true });
      const siteId = payload.site_id;
      const s3Uploads = [];
      let files = [];
      let parts = [];
      if (payload.file.length > 0) {
        for (const item of payload.file) {
          let file = item;
          let fileName = "";
          let fileSize = item.size;
          if (item.isFile) {
            file = await getFile(item);
            fileName = item.fullPath.replace("/", "");
          } else if (item.webkitRelativePath) {
            fileName = item.webkitRelativePath;
          } else {
            fileName = item.name;
          }
          files.push(file);
          const body = {
            name: fileName,
            kind: payload.kind,
            file_size: fileSize,
          };
          // Post to send initial data and receive s3 pre-signed url
          const s3FileResponse = await axios.post(
            `/sites/${siteId}/data/raw`,
            body,
          );
          if (s3FileResponse.status === 200) {
            // Update the current list of uploads in the table
            s3FileResponse.data.upload.uploading = true;
            s3Uploads.push(s3FileResponse.data);
            commit("addNewSiteRawdata", s3FileResponse.data);
          } else {
            dispatch(
              "error/raiseError",
              {
                name: "postSiteRawdata",
                ...{
                  code: s3FileResponse.status,
                  message: "Error attachment API call",
                  value: "unknown error",
                },
              },
              { root: true },
            );
          }
        }
        if (s3Uploads.length > 0) {
          const uploadProgressObj = {};
          s3Uploads.forEach((item) => {
            const uploadId = item.upload.id;
            const urls = item.urls;
            const urlsObj = {};

            Object.keys(urls).forEach((key) => {
              urlsObj[urls[key]] = false;
            });

            uploadProgressObj[uploadId] = urlsObj;
          });
          commit("setUploadProgress", uploadProgressObj);
          for (const [i, uploadId] of Object.keys(
            uploadProgressObj,
          ).entries()) {
            // Create and upload parts
            parts = await dispatch("uploadParts", {
              file: files[i],
              urls: Object.keys(uploadProgressObj[uploadId]),
              uploadId: uploadId,
            });
            // Confirm that the upload has completed and update status
            const res = await axios({
              method: "put",
              url: `sites/${siteId}/data/raw/user/${s3Uploads[i].upload.id}`,
              data: JSON.stringify({
                upload_id: s3Uploads[i].post.UploadId,
                parts: parts,
              }),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            });

            if (res.status === 200) {
              // Set a loading property for each new upload
              res.data.uploading = false;
              commit("setRawdataUpdated", true);
              commit("updateRawdata", res.data);
            } else {
              dispatch(
                "error/raiseError",
                {
                  name: "postSiteRawdata",
                  ...{
                    code: res.status,
                    message: "Error API call",
                    value: "unknown error",
                  },
                },
                { root: true },
              );
            }
          }
        }
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "postSiteRawdata", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "postSiteRawdata", value: false });
  },
  async addNewSite({ commit, dispatch, rootState }, payload) {
    try {
      commit("setLoading", { name: "addNewSite", value: true });
      commit("setSite", {});
      const contract_power_curves = [];
      for (let i = 0; i < payload.form.contractPowerCurve.length; i++) {
        contract_power_curves.push({
          airdensity_kgpm3: payload.form.contractPowerCurve[i].airDensity,
          vals: {
            realpower_kw: payload.form.contractPowerCurve[i].powerCurve.power,
            windspeed_mps:
              payload.form.contractPowerCurve[i].powerCurve.windSpeed,
          },
        });
        if (payload.form.contractPowerCurveType === "turbine") {
          contract_power_curves[i].turbine_name =
            payload.form.contractPowerCurve[i].name;
        } else {
          contract_power_curves[i].turbine_model_id =
            payload.form.contractPowerCurve[i].turbineModel.id;
        }
      }

      const params = {};
      params.user_aws_sub = rootState.user.awsSub;
      params.state = payload.form.state ? payload.form.state : "inactive";
      params.org_id = payload.form.org.id;
      params.name = payload.form.site;
      params.latitude_deg = payload.form.latitude;
      params.longitude_deg = payload.form.longitude;
      if (payload.form.location) params.location = payload.location;
      if (payload.form.timeZone) params.time_zone = payload.form.timeZone;
      if (payload.form.notes) params.notes = payload.form.notes;
      if (payload.form.powerPrice && payload.form.powerPriceDate) {
        params.power_price = {
          price: payload.form.powerPrice,
          ts: dayjs(payload.form.powerPriceDate).format("YYYY-MM-DD"),
        };
      }
      if (payload.form.staleTime)
        params.scada_stale_time = payload.form.staleTime;
      if (payload.form.staleTime10m)
        params.raw_10m_stale_time_hr = payload.form.staleTime10m;
      if (payload.form.staleTimeEvent)
        params.event_stale_time_hr = payload.form.staleTimeEvent;
      if (!payload.form.turbinesNotComplete) {
        params.turbines = payload.form.turbines.map((turbine) => ({
          name: `${payload.form.orgAbbrev}-${payload.form.siteAbbrev}-${turbine.customer_name}`,
          turbine_model_id: turbine.turbine_model.id,
          latitude_deg: turbine.latitude_deg,
          longitude_deg: turbine.longitude_deg,
          elevation_m: +turbine.elevation_m,
          year_installed: turbine.year_installed,
        }));
      }
      if (!payload.subscriptionsNotComplete) {
        params.subscriptions = payload.form.subscriptions.map(
          (subscription) => ({
            name: subscription.subscription_type.name,
            sow_def_id: subscription.subscription_type.sow_def_id,
            start_date: dayjs(subscription.startDate).format("YYYY-MM-DD"),
            end_date: dayjs(subscription.endDate).format("YYYY-MM-DD"),
          }),
        );
      }
      params.analytics_settings = {
        impute_from_gen_speed: payload.form.imputeRotorSpeed,
        gear_ratio: payload.form.gearboxRatio,
      };
      if (payload.form.contractPowerCurveType) {
        params.contract_power_curve_type = payload.form.contractPowerCurveType;
      }
      params.contract_power_curves = contract_power_curves;

      const res = await axios.post(`/sites`, params);

      if (res.status === 200) {
        commit("setSiteResponse", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "addNewSite",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "addNewSite", error },
        { root: true },
      );
    } finally {
      commit("setLoading", { name: "addNewSite", value: false });
    }
  },
  async updateSite({ commit, dispatch, rootState }, payload) {
    try {
      commit("setLoading", { name: "updateSite", value: true });
      commit("setSite", {});
      const contract_power_curves = [];
      for (let i = 0; i < payload.form.contractPowerCurve.length; i++) {
        contract_power_curves.push({
          airdensity_kgpm3: +payload.form.contractPowerCurve[i].airDensity,
          vals: {
            realpower_kw: payload.form.contractPowerCurve[i].powerCurve.power,
            windspeed_mps:
              payload.form.contractPowerCurve[i].powerCurve.windSpeed,
          },
        });
        if (payload.form.contractPowerCurveType === "turbine") {
          contract_power_curves[i].turbine_name =
            payload.form.contractPowerCurve[i].name;
        } else {
          contract_power_curves[i].turbine_model_id =
            payload.form.contractPowerCurve[i].turbineModel.id;
        }
      }

      const params = {};
      params.user_aws_sub = rootState.user.awsSub;
      params.state = payload.form.state;
      params.org_id = payload.form.org.id;
      params.site_id = payload.siteId;
      params.name = payload.form.site;
      params.latitude_deg = payload.form.latitude;
      params.longitude_deg = payload.form.longitude;
      if (payload.form.location) params.location = payload.form.location;
      if (payload.form.timeZone) params.time_zone = payload.form.timeZone;
      if (payload.form.notes) params.notes = payload.form.notes;
      if (payload.form.powerPrice && payload.form.powerPriceDate) {
        params.power_price = {
          price: parseInt(payload.form.powerPrice),
          ts: dayjs(payload.form.powerPriceDate).format("YYYY-MM-DD"),
          id: payload.form.powerPriceId,
        };
      }
      if (payload.form.staleTime)
        params.scada_stale_time = payload.form.staleTime;
      if (payload.form.staleTime10m)
        params.raw_10m_stale_time_hr = payload.form.staleTime10m;
      if (payload.form.staleTimeEvent)
        params.event_stale_time_hr = payload.form.staleTimeEvent;
      if (!payload.turbinesNotComplete) {
        params.turbines = payload.form.turbines.map((turbine) => ({
          name: `${payload.form.orgAbbrev}-${payload.form.siteAbbrev}-${turbine.customer_name}`,
          id: turbine.id,
          turbine_model_id: turbine.turbine_model.id,
          latitude_deg: turbine.latitude_deg,
          longitude_deg: turbine.longitude_deg,
          elevation_m: +turbine.elevation_m,
          year_installed: turbine.year_installed,
        }));
      }
      if (!payload.subscriptionsNotComplete) {
        params.subscriptions = payload.form.subscriptions.map(
          (subscription) => ({
            name: subscription.subscription_type.name,
            id: subscription.id ? subscription.id : null,
            sow_def_id: subscription.subscription_type.sow_def_id,
            start_date: dayjs(subscription.startDate).format("YYYY-MM-DD"),
            end_date: dayjs(subscription.endDate).format("YYYY-MM-DD"),
          }),
        );
      }
      params.analytics_settings = {
        impute_from_gen_speed: payload.form.imputeRotorSpeed,
        gear_ratio: payload.form.gearboxRatio,
      };
      if (payload.form.contractPowerCurveType) {
        params.contract_power_curve_type = payload.form.contractPowerCurveType;
      }
      params.contract_power_curves = contract_power_curves;

      const res = await axios.put(`/sites/${payload.siteId}`, params);

      if (res.status === 200) {
        commit("setSiteResponse", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "updateSite",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "updateSite", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "updateSite", value: false });
  },
  // Boolean to track if siteData was updated with finished upload
  isRawdataUpdated({ commit }, value) {
    commit("setRawdataUpdated", value);
  },
  async uploadParts({ commit, dispatch, rootState }, payload) {
    const FILE_CHUNK_SIZE = 20_000_000;
    const axios = Axios.create();
    delete axios.defaults.headers.put["Content-Type"];
    const keys = Object.keys(payload.urls);
    const promises = [];

    for (const indexStr of keys) {
      const index = parseInt(indexStr);
      const start = index * FILE_CHUNK_SIZE;
      const end = (index + 1) * FILE_CHUNK_SIZE;
      const blob =
        index < keys.length
          ? payload.file.slice(start, end)
          : payload.file.slice(start);
      promises.push(
        axios.put(payload.urls[index], blob).then((response) => {
          commit("setUploadFinished", {
            uploadId: payload.uploadId,
            presignedUrl: payload.urls[index],
          });
          return response;
        }),
      );
    }
    const resParts = await Promise.all(promises);
    return resParts.map((part, index) => ({
      etag: part.headers.etag,
      part_number: index + 1,
    }));
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setTurbines(state, data) {
    if (!data.statusText) {
      state.turbines = data;
      for (const item of state.turbines) {
        state.turbineIds.push(item.turbine_id);
      }
    } else {
      state.turbines = [];
    }
  },
  setEditPowerPriceResponse(state, data) {
    state.editPowerPriceResponse = data;
  },
  setSiteRawdata(state, data) {
    for (const item of data) {
      item.kind = setUploadType(item.kind);
    }
    state.siteRawdata = data;
  },
  addNewSiteRawdata(state, data) {
    state.isUploading = true;
    const rawDataItem = {
      id: data.upload.id,
      name: data.upload.name,
      user_email: data.upload.user_email,
      upload_start_ts: data.upload.upload_start_ts,
      status: data.upload.status,
      kind: setUploadType(data.upload.kind),
      notes: data.upload.notes,
      uploading: true,
    };
    state.siteRawdata.push(rawDataItem);
  },
  updateRawdata(state, data) {
    let found = state.siteRawdata.find((item) => item.id === data.id);
    data.kind = setUploadType(data.kind);
    if (found) {
      const foundIndex = state.siteRawdata.findIndex(
        (item) => item.id === found.id,
      );
      state.siteRawdata[foundIndex] = data;
    }
    const uploadsLoading = state.siteRawdata.filter((item) => item.uploading);
    if (uploadsLoading.length === 0) {
      state.isUploading = false;
    }
  },
  setRawdataUpdated(state, data) {
    state.rawdataUpdated = data;
  },
  setSiteResponse(state, data) {
    state.siteResponse = data;
  },
  setSite(state, data) {
    state.site = data;
  },
  setAllSites(state, data) {
    state.allSites = data;
  },
  setPowerPrice(state, data) {
    state.powerPrice = data;
  },
  setUploadProgress(state, data) {
    state.uploadProgress = data;
  },
  setUploadFinished(state, data) {
    state.uploadProgress[data.uploadId][data.presignedUrl] = true;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
