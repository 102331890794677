import axios from "@/helpers/axiosAPI";
import { stringToColor, userInitials } from "../../helpers/functions";
import jwt_decode from "jwt-decode";
import mixpanel from "mixpanel-browser";

// initial state
const state = () => ({
  loading: {
    login: false,
    forgotPassword: false,
    confirmPasswordCode: false,
    getUserData: false,
    getUserSettings: false,
    updateUserSettings: false,
  },
  userData: null,
  accessToken: localStorage.getItem("access_token"),
  idToken: localStorage.getItem("id_token"),
  awsSub: localStorage.getItem("aws_sub"),
  refreshToken: localStorage.getItem("refresh_token"),
  userSettings: {},
  updateSettingsResponse: {},
});

// actions
const actions = {
  async login({ commit, dispatch }, { email, password }) {
    commit("setLoading", { name: "login", value: true });

    try {
      var formData = new FormData();
      formData.append("username", email);
      formData.append("password", password);
      const res = await axios.post("/auth", formData);

      if (res.status === 200) {
        if (!res.data.access_token) {
          commit("setLoading", { name: "login", value: false });
          return false;
        }
        commit("setAccessToken", res.data.access_token);
        commit("setIdToken", res.data.id_token);
        commit("setRefreshToken", res.data.refresh_token);
        // Decode token to get sub
        const decoded = jwt_decode(res.data.access_token);
        commit("setAWSSub", decoded.sub);
        await dispatch("getUserData");
        commit("setLoading", { name: "login", value: false });
        dispatch(
          "error/dismissErrorsByName",
          { name: "login" },
          { root: true },
        );
        return true;
      }

      localStorage.clear();
      dispatch(
        "error/raiseError",
        {
          name: "login",
          ...{
            code: res.status,
            message: "Error API call",
            value: "unknown error",
          },
        },
        { root: true },
      );
    } catch (error) {
      localStorage.clear();
      dispatch("error/raiseError", { name: "login", error }, { root: true });
    }

    commit("setLoading", { name: "login", value: false });

    return false;
  },
  async forgotPassword({ commit, dispatch }, email) {
    commit("setLoading", { name: "forgotPassword", value: true });

    try {
      const res = await axios.put("/user/password", null, {
        params: { email },
      });

      if (res.status === 200) {
        commit("setLoading", { name: "forgotPassword", value: false });
        return true;
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "forgotPassword", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "forgotPassword", value: false });
    return false;
  },
  async confirmPasswordCode({ commit, dispatch }, { email, password, code }) {
    commit("setLoading", { name: "confirmPasswordCode", value: true });

    const params = { email };
    const body = { code, password };

    try {
      const res = await axios.put("/user/password", body, { params });

      if (res.status === 200) {
        commit("setLoading", { name: "confirmPasswordCode", value: false });
        return true;
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "confirmPasswordCode",
            ...{
              code: res.status,
              message: res.message,
              value: "unknown error",
            },
          },
          { root: true },
        );
        return false;
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "confirmPasswordCode", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "confirmPasswordCode", value: false });

    return false;
  },
  async getUserData({ commit, dispatch }) {
    try {
      commit("setLoading", { name: "getUserData", value: true });

      const res = await axios.get("/user");

      if (res.status === 200) {
        // Add a bunch of backward compatibility aliases to this object
        res.data["advanced_permissions"] = res.data.permissions;
        res.data["permissions"] = res.data.simplified_permissions;
        res.data["full_name"] = res.data.name_first + " " + res.data.name_last;
        res.data["user_id"] = res.data.id;
        mixpanel.identify(res.data.id);
        mixpanel.people.set({
          $is_internal: res.data.is_internal,
          $company: res.data.email.split("@")[1],
        });
        commit("setUserData", {
          ...res.data,
          user_initials: userInitials(res.data.full_name),
          user_color: stringToColor(res.data.full_name),
        });
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getUserData",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getUserData", error },
        { root: true },
      );
    }
    commit("setLoading", { name: "getUserData", value: false });
  },
  async getUserSettings({ commit, dispatch, rootState }) {
    try {
      commit("setLoading", { name: "getUserSettings", value: true });

      const res = await axios.get("/user/settings");

      if (res.status === 200) {
        commit("setUserSettings", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "getUserSettings",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "getUserSettings", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "getUserSettings", value: false });
  },
  async updateUserSettings({ commit, dispatch, rootState }, payload) {
    try {
      commit("setLoading", { name: "updateUserSettings", value: true });
      const params = {
        email_all_notifications: payload.email_all_notifications,
        email_digest_freq: payload.email_digest_freq,
        theme: payload.theme,
      };

      const res = await axios.put("/user/settings", null, { params });

      if (res.status === 200) {
        commit("setUpdateSettingsResponse", res.data);
      } else {
        dispatch(
          "error/raiseError",
          {
            name: "updateUserSettings",
            ...{
              code: res.status,
              message: "Error API call",
              value: "unknown error",
            },
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        "error/raiseError",
        { name: "updateUserSettings", error },
        { root: true },
      );
    }

    commit("setLoading", { name: "updateUserSettings", value: false });
  },
  logout() {
    /*
      Clear out all local storage keys and values from
      the this domain and empty the store
     */
    let state = this.state;
    let newState = {};

    Object.keys(state).forEach((key) => {
      console.info(key);
      newState[key] = null;
    });
    this.replaceState(newState);
    localStorage.clear();
    mixpanel.reset();
    location.reload();
  },
};

// mutations
const mutations = {
  setLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
  setUserData(state, data) {
    state.userData = data && data.user_id ? data : null;
  },
  setAccessToken(state, value) {
    state.accessToken = value;

    if (value) {
      localStorage.setItem("access_token", value);
    } else {
      localStorage.removeItem("access_token");
    }
  },
  setIdToken(state, value) {
    state.idToken = value;

    if (value) {
      localStorage.setItem("id_token", value);
    } else {
      localStorage.removeItem("id_token");
    }
  },
  setAWSSub(state, value) {
    state.awsSub = value;

    if (value) {
      localStorage.setItem("aws_sub", value);
    } else {
      localStorage.removeItem("aws_sub");
    }
  },
  setRefreshToken(state, value) {
    state.refreshToken = value;

    if (value) {
      localStorage.setItem("refresh_token", value);
    } else {
      localStorage.removeItem("refresh_token");
    }
  },
  setUserSettings(state, data) {
    state.userSettings = data;
  },
  updateLoadingGetSettings(state, data) {
    state.loadingGetSettings = data;
  },
  setUpdateSettingsResponse(state, data) {
    state.updateSettingsResponse = data;
  },
  updateLoadingSetSettings(state, data) {
    state.loadingSetSettings = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
